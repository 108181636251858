import EChartsReact from 'echarts-for-react';
import { useEffect, useState } from 'react';

export default function ModelScoreChart({ data }) {
  const [barChartOptions, setBarChartOptions] = useState({});

  useEffect(() => {
    if (data) {
      setBarChartOptions({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          // 툴팁 위치를 마우스 오른쪽 아래로 설정
          position: function (point) {
            // point: [x, y] of mouse position
            return [point[0] + 10, point[1] + 10]; // 마우스 포인터의 x, y 좌표에서 10px 아래, 10px 오른쪽으로 이동
          },
        },
        grid: {
          top: '12%',
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          min: 0,
          max: 100,
          interval: 10,
          axisLabel: {
            formattter: '{value}'
          }
        },
        yAxis: {
          type: 'category',
          data: data.map((item) => item.filename),
          triggerEvent: true,
          axisLabel: {
            show: true,
          },
          inverse: true,
        },
        series: [
          {
            type: 'bar',
            data: data.map((item, index) => {
              if (index == 0) {
                return {
                  value: item.calcscore,
                  itemStyle: {
                    color: '#a90000',
                  },
                };
              } else {
                return item.calcscore;
              }
            }),
            animation: false,
          },
        ],
      });
    }
  }, [data]);

  return (
    <>
      <EChartsReact style={{ height: '100%' }} option={barChartOptions} />
    </>
  );
}
