import { useState, useEffect } from "react"
import { toast } from "react-toastify";
import useLoading from "../../hooks/useLoading";
import Neo4jGraph from '../Components/Neo4jGraph';
import neo4j from 'neo4j-driver';

export default function OntologyGuide() {

  const [showChart, setShowChart] = useState(false)
  const [showTable, setShowTable] = useState(false);
  const [isTask, setIsTask] = useState(false)

  const [keyword, setKeyword] = useState('')
  const [selRoleOption, setSelRoleOption] = useState(false)
  const [selStageOption, setSelStageOption] = useState(false)
  const [selStepOption, setSelStepOption] = useState(false)
  const [options1, setOptions1] = useState([])
  const [options2, setOptions2] = useState([])
  const [options3, setOptions3] = useState([])
  const [searchList, setSearchList] = useState([])

  const { LoadingElement, loading, setLoading } = useLoading({ init: false })

  const [cypherQuery, setCypherQuery] = useState('');

  const uri = process.env.REACT_APP_NEO4J_URI;
  const user = process.env.REACT_APP_NEO4J_USER;
  const password = process.env.REACT_APP_NEO4J_PASSWORD;

  /**
   * 단계목록조회
   */
  const getStageList = async () => {
    const driver = neo4j.driver(uri, neo4j.auth.basic(user, password));
    const session = driver.session();
    try {
      const result = await session.run(
        "Match (R:Role{name: $role})-[:Include]-(S:Stage) Return S.name AS content",
        { role: selRoleOption }
      );
      const records = result.records.map(record => ({
        content: record.get('content')
      }));

      setOptions2(records);
    } catch (error) {
      console.error("Neo4j query error:", error);
    } finally {
      await session.close();
      await driver.close();
    }
  };

  /**
   * 세부단계목록조회
   */
  const getStepList = async () => {
    const driver = neo4j.driver(uri, neo4j.auth.basic(user, password));
    const session = driver.session();
    try {
      const result = await session.run(
        "Match (R:Role{name: $role})-[:Include]-(S:Stage{name: $step})-[:Include]-(D:Step) Return D.name AS content",
        { role: selRoleOption, step: selStageOption }
      );
      const records = result.records.map(record => ({
        content: record.get('content')
      }));
      console.log("🚀 ~ records ~ records:", records)

      setOptions3(records);
    } catch (error) {
      console.error("Neo4j query error:", error);
    } finally {
      await session.close();
      await driver.close();
    }
  };

  useEffect(() => {
    getStageList();
    resetSelectBox();
  }, [selRoleOption]);

  useEffect(() => {
    getStepList();
  }, [selStageOption]);

  const resetSelectBox = () => {
    setSelStageOption(false);
    setSelStepOption(false);
    setOptions2([]);
    setOptions3([]);
  }

  const searchOntology = () => {
    if (selRoleOption != false) {
      setLoading(true)
      setTimeout(() => {
        // setShowChart(true)
        const roleQuery = (!selRoleOption ? '(R:Role)' : `(R:Role{name:'${selRoleOption}'})`)
        const stageQuery = (!selStageOption ? '(D:Stage)' : `(D:Stage{name:'${selStageOption}'})`)
        const stepQuery = (!selStepOption ? '(S:Step)' : `(S:Step{name:'${selStepOption}'})`)

        const query = `MATCH ${roleQuery}-[rel1:Include]-${stageQuery}-[rel2:Include]-${stepQuery} RETURN R, rel1, S, rel2, D`

        // const query = `MATCH (w {name : 'BIM 성과품 납품'})
        // MATCH (w)-[r]->(p)
        // RETURN w, p, r`

        console.log("🚀 ~ searchOntology ~ query:", query)
        setCypherQuery(query)
        setLoading(false)
      }, 1);
    } else {
      toast.warn('검색조건을 선택해주세요')
    }
  }

  const search = () => {
    if (keyword != '' && keyword != null) {
      getSearchList()
    }
  }

  const getSearchList = async () => {
    const driver = neo4j.driver(uri, neo4j.auth.basic(user, password));
    const session = driver.session();
    try {
      const result = await session.run(
        ` MATCH (k:Keyword {name: $keyword})
          MATCH (k)-[:Include]-(n:Detail)
          MATCH (n)-[:Include]-(c:Content)
          RETURN c.stage AS stage, c.step AS step, c.role AS role, c.content AS content, n.detail AS detail`,
        { keyword: keyword.toUpperCase() }
      );
      const records = result.records.map(record => ({
        stage: record.get('stage'),
        step: record.get('step'),
        role: record.get('role'),
        content: record.get('content'),
        detail: record.get('detail'),
      }));

      setSearchList(records);
    } catch (error) {
      console.error("Neo4j query error:", error);
    } finally {
      await session.close();
      await driver.close();
    }
  };

  return (
    <>
      <article className="mt-5 contentsbox px-5">
        <div className="flex items-center">
          <h3 className="flex items-center grow">
            <img src="/images/tit_icon3.png" alt="icon" width="33" />
            <span className="ml-2.5 text-2xl font-bold">온톨로지 기반 지침 검색</span>
          </h3>
        </div>
        <div className="mt-4 flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              style={{ width: '600px' }}
              value={"온톨로지 기반 지침 검색은 ‘지침별’ / ‘단계별’/ ‘세부단계별’ 검색이 가능합니다."}
              disabled
            />
          </label>
        </div>
        <div className="mt-4 flex items-center gap-1 w-[70%]">
          <select className="select1 w-full" value={selRoleOption} style={{ height: '40px' }} onChange={(e) => {
            setSelRoleOption(e.target.value)
          }}>
            <option value={false}>지침을 선택하세요</option>
            <option value={'시공자'}>건설산업 BIM 시행지침 시공자편</option>
            <option value={'설계자'}>건설산업 BIM 시행지침 설계자편</option>
            <option value={'단지 분야'}>건설산업 BIM 시행지침 단지분야</option>
          </select>
          <select className="select1 w-full" value={selStageOption} style={{ height: '40px' }} onChange={(e) => {
            setSelStageOption(e.target.value)
          }}>
            <option value={false}>단계를 선택하세요</option>
            {options2.map((item, index) => (
              <option key={`stage-${index}`} value={item.content}>{item.content}</option>
            ))}
          </select>
          <select className="select1 w-full" value={selStepOption} style={{ height: '40px' }} onChange={(e) => {
            setSelStepOption(e.target.value)
          }}>
            <option value={false}>세부단계를 선택하세요</option>
            {options3.map((item, index) => (
              <option key={`step-${index}`} value={item.content}>{item.content}</option>
            ))}
          </select>
          <a
            href="javascript:void(0)"
            style={{ height: '40px' }}
            className="btn_h45 r5 px-10 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={searchOntology}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>
        <div className="mt-5 relative r5 bg-slate-100">
          <LoadingElement />
          {/* <img src="/images/ontology_chart2.png" style={{ opacity: `${showChart ? 1 : 0}` }} /> */}
          <Neo4jGraph query={cypherQuery} isTask={isTask} />
        </div>

        <div className="mt-8  flex items-center">
          <h3 className="flex items-center grow">
            <img src="/images/tit_icon3.png" alt="icon" width="33" />
            <span className="ml-2.5 text-2xl font-bold">키워드 기반 지침 검색</span>
          </h3>
        </div>
        {/* <div className="mt-4 flex items-center gap-1 w-[70%]">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              style={{ width: '600px' }}
              value={"온톨로지 기반 지침 검색은 ‘지침별’ / ‘단계별’/ ‘세부단계별’ 검색이 가능합니다."}
              disabled
            />
          </label>
        </div> */}
        <div className="mt-4 flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              style={{ width: '300px' }}
              placeholder="키워드를 입력해주세요"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  search()
                }
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            style={{ height: '45px' }}
            className="btn_h45 r5 px-10 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={search}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>
        <table className="mt-4 w-full ">
          <colgroup>
            <col width="5%" />
            <col width="10%" />
            <col width="12%" />
            <col width="8%" />
            <col width="*" />
          </colgroup>
          <thead className="thead1">
            <tr className="text-center tracking-tighter">
              <th className="p-2">번호 </th>
              <th className="p-2">단계</th>
              <th className="p-2">적용단계</th>
              <th className="p-2">역할</th>
              <th className="p-2">내용</th>
            </tr>
          </thead>
          <tbody className="tbody1 f_15 text-center tracking-tighter">
            {
              searchList.length > 0 ? (
                searchList.map((item, index) => (
                  <tr key={`search-${index + 1}`}>
                    <td className="py-2.5 px-5" >
                      {index + 1}
                    </td>
                    <td className="py-2.5 px-5">
                      <span className="text-indigo-950/70 ">{item.stage}</span>
                    </td>
                    <td className="py-2.5 px-5 text-left">
                      {item.step}
                    </td>
                    <td>{item.role}</td>
                    <td className="py-3">
                      {item.detail}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="py-2.5 px-5" colSpan={5}>
                    검색된 결과가 없습니다.
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>

      </article>
    </>
  )
}