import { React, useEffect, useState } from 'react';
import OntologyDocPop from './OntologyDocPop';
import OntologyOptionPop from './OntologyOptionPop';
import { useSelector } from 'react-redux';
import ReactTable from '../../../Components/ReactTable';
import Pagination from '../../../Components/Pagination';
import { useSearchParams } from 'react-router-dom';
import axiosClient from '../../../../lib/axiosClient';
import useLoading from '../../../../hooks/useLoading';
import useTableControl from '../../../../hooks/useTableControl';
import OntologyResultTable from './OntologyReseultTable';
export default function OntologyAnalysis({
  reAnalysis,
  analysisResult,
  analysisInfo,
  analysisOption,
  downloadReport
}) {
  const status = analysisResult?.['obdaStep'] || analysisInfo?.['obdaStep']
  const [docPop, setDocPop] = useState(false);
  const [optionPop, setOptionPop] = useState(false);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  const { LoadingElement, loading, setLoading } = useLoading({
    init: status == 1 ? true : false,
    size: 14,
  });
  const [aiDifficultyList, setAiDifficultyList] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
    filterOptions,
    setFilterOptionValue
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = searchParams.get('history');
  const [ontologyResultList, setOntologyResultList] = useState([]);
  // 클릭된 로우 하이라이트 처리
  const clickedRowHighlight = (data) => {
    if (selectedRow) {
      setAiDifficultyList(
        data?.map((item) =>
          selectedRow?.difficultyId == item.difficultyId
            ? {
              ...item,
              style: {
                background: '#bbbb',
              },
            }
            : {
              ...item,
              style: null,
            },
        ),
      );
    } else {
      setAiDifficultyList(data);
    }
  };

  // AI난이도 분석 결과 조회
  const getAiDifficultyList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAiDifficultyList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          ...filterOptions,
          historyId,
          projectId,
          llmData: analysisOption.useLlm,
          fromOntology: 1
        },
      },
    })
      .then(async (result) => {
        clickedRowHighlight(result.data.data.rows)
        setTotalCount(result.data.data.totalCount);
        setOntologyResultList(result.data.data.ontologyResult)
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  useEffect(() => {
    setLoading(status == 1 ? true : false);
  }, [analysisResult]);

  useEffect(() => {
    getAiDifficultyList();
  }, [pageInfo, sortOption, analysisResult, filterOptions]);

  // 컬럼명 설정
  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
        size: '13%',
      },
      {
        header: '문장',
        id: 'sentence',
        textAlign: 'text-left',
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <div className="text-red-600">{row.sentence}</div>;
          } else if (row.difficulty == 'MID') {
            return <div className="text-blue-600">{row.sentence}</div>;
          } else {
            return <div>{row.sentence}</div>;
          }
        }
      },
      {
        header: '난이도 문장',
        id: 'difficulty_order',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'difficulty_order' })?.sort || '',
        },
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <span className="tdttbox px-2 bg-red-600/70">상</span>;
          } else if (row.difficulty == 'MID') {
            return <span className="tdttbox px-2 bg-lime-600/60">중</span>;
          } else {
            return <span className='tdttbox px-2 bg-sky-600/60'>하</span>;
          }
        },
        size: '18%',
        filter: () => {
          return (
            <>
              <select className='ml-2 px-2 py-1 border-2' onChange={(e) => { setFilterOptionValue('difficulty', e.target.value) }}>
                <option value={''}>
                  전체
                </option>
                {
                  [{ title: '상', value: 'HIGH' }, { title: '중', value: 'MID' }, { title: '하', value: 'LOW' }].map(v => {
                    return (
                      <option value={v.value}>
                        {v.title}
                      </option>
                    )
                  })
                }
              </select>
            </>
          )
        }
      },
    ]);
  }, []);

  useEffect(() => {
    clickedRowHighlight(aiDifficultyList);
  }, [selectedRow]);

  const search = () => {
    onPageChange(1)
    getAiDifficultyList()
    setSelectedRow(null)
  }

  return (
    <>
      <div className="ml-12 conbox relative" style={{ overflowY: `${loading ? 'hidden' : 'auto'}` }}>
        <div className="flex items-center justify-between">
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">
              AI기반 난이도 분석 결과 - {analysisOption.useLlm == 0 ? '자체분석데이터' : 'LLM기반 데이터'}
            </span>
          </h4>
          <p className="flex gap-1">
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                reAnalysis(3)
                setSelectedRow(null)
              }}
            >
              재분석
            </a>
            <a
              onClick={downloadReport}
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
            >
              다운로드
            </a>
            {/* <a
              href="javascript:void(0)"
              className="btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setOptionPop(true);
              }}
            >
              전문가 모드
            </a> */}
          </p>
        </div>

        <LoadingElement />
        {status == -1 && <div className="loading"></div>}
        <div className="mt-4 flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              placeholder="문장을 입력해주세요."
              style={{ width: '350px' }}
              value={getSearchOptionValue('sentence')}
              onChange={(e) => {
                setSearchOptionValue('sentence', e.target.value, 1);
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  search()
                }
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={search}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>
        <ReactTable
          className="mt-4 w-full"
          columns={columns}
          data={aiDifficultyList}
          setData={setAiDifficultyList}
          totalCount={totalCount}
          onSortingChange={onSortingChange}
          pageInfo={pageInfo}
          onRowClick={(row) => {
            setSelectedRow(row);
          }}
        />
        <Pagination
          currentPage={pageInfo.currentPage}
          totalCount={totalCount}
          pageSize={pageInfo.pageSize}
          onPageChange={onPageChange}
        />

        <dl className="mt-8">
          <dt className=" text-lg font-bold">대상문장</dt>
          <dd className="mt-2 flex items-center gap-1">
            <label className="grow">
              <input
                type="text"
                className="input_h45 f_15 px-3 r5 bg-slate-50 w-full"
                value={`문장 : ${selectedRow
                  ? selectedRow.sentence
                  : '위의 테이블에서 문장을 선택해주세요'
                  }`}
              />
            </label>
            {/* <a
              href="javascript:void(0)"
              className="btn_h45 r5 px-7 bg-indigo-950/40 hover:bg-indigo-900 "
              onClick={() => {
                setDocPop(true);
              }}
            >
              <span className="text-sm text-white font-bold ">변경</span>
            </a> */}
          </dd>
        </dl>
        <OntologyResultTable ontologyResultList={ontologyResultList} selectedRow={selectedRow} />
      </div>
      {docPop && (
        <OntologyDocPop setIsOpen={setDocPop} projectStatus={projectStatus} />
      )}
      {optionPop && (
        <OntologyOptionPop
          setIsOpen={setOptionPop}
          projectStatus={projectStatus}
        />
      )}
    </>
  );
}
