import React, { useEffect, useRef, useState } from 'react';
import { scaleLinear } from 'd3-scale';
import WordCloud from 'wordcloud';
import axiosClient from '../../lib/axiosClient';

export default function WordCloudChart({ data, useMask = true }) {
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const [maskImage, setMaskImage] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (data && canvas) {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const canvasArea = canvasWidth * canvasHeight;

      const minValue = Math.min(...data.map((d) => d['frequency']));
      const maxValue = Math.max(...data.map((d) => d['frequency']));
      const totalFrequency = data.length;

      // 적절한 폰트 크기 스케일 정의 (캔버스 크기 및 데이터의 총 빈도를 기반으로 함)
      const maxFontSize = Math.sqrt(canvasArea / totalFrequency); // 캔버스 크기에 따라 최대 폰트 크기 설정
      const fontSizeScale = scaleLinear()
        .domain([minValue, maxValue])
        .range([8, maxFontSize]);

      const newWordCloudData = data.map((item) => [
        item.word,
        fontSizeScale(item['frequency']),
      ]);

      const options = {
        list: newWordCloudData,
        gridSize: Math.round((16 * canvas.width) / 1024),
        weightFactor: 2,
        fontFamily: 'Times',
        color: () => `hsl(${Math.random() * 360}, 100%, 50%)`,
        rotateRatio: 0.5,
        rotationSteps: 2,
        backgroundColor: '#fff',
        clearCanvas: maskImage ? false : true,
      };

      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (maskImage) {
        const img = new Image();
        img.src = maskImage;
        img.crossOrigin = "anonymous";

        img.onload = () => {
          const maskCanvas = document.createElement('canvas');
          maskCanvas.width = canvas.width;
          maskCanvas.height = canvas.height;
          const maskCtx = maskCanvas.getContext('2d');

          maskCtx.drawImage(img, 0, 0, maskCanvas.width, maskCanvas.height);
          const imageData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height);

          // 검정색 픽셀 비율 계산
          let blackPixelCount = 0;
          const totalPixelCount = imageData.data.length / 4;
          for (let i = 0; i < imageData.data.length; i += 4) {
            const isBlackPixel =
              imageData.data[i] === 0 &&
              imageData.data[i + 1] === 0 &&
              imageData.data[i + 2] === 0;

            if (isBlackPixel) {
              blackPixelCount++;
            }
          }

          const blackPixelRatio = blackPixelCount / totalPixelCount;

          // 검정색 비율에 따라 폰트 크기 재조정
          const adjustedMaxFontSize = Math.sqrt(canvasArea * blackPixelRatio / totalFrequency) * 1
          const adjustedFontSizeScale = scaleLinear()
            .domain([minValue, maxValue])
            .range([8, adjustedMaxFontSize]);

          const adjustedWordCloudData = data.map((item) => [
            item.word,
            adjustedFontSizeScale(item['frequency']),
          ]);

          const newImageData = maskCtx.createImageData(imageData);
          const bgColor = [255, 255, 255, 255];

          // 현재 마스크 이미지가 검정색인 부분에 워드클라우드가 그려진다.
          for (let i = 0; i < imageData.data.length; i += 4) {
            if (
              imageData.data[i] === 0 &&
              imageData.data[i + 1] === 0 &&
              imageData.data[i + 2] === 0
            ) {
              newImageData.data[i] = bgColor[0];
              newImageData.data[i + 1] = bgColor[1];
              newImageData.data[i + 2] = bgColor[2];
              newImageData.data[i + 3] = bgColor[3];
            } else {
              newImageData.data[i + 3] = 0;
            }
          }

          maskCtx.putImageData(newImageData, 0, 0);
          ctx.drawImage(maskCanvas, 0, 0);

          WordCloud(canvas, { ...options, list: adjustedWordCloudData });
        };
      } else {
        WordCloud(canvas, options);
      }
    }

    return () => {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [data, maskImage]);

  const changeMaskImage = async (type) => {
    if (type == 1) {
      setMaskImage(null);
    } else {
      const imageName = type == 3 ? 'cloud.png' : 'brain.png';
      const response = await axiosClient.get("/api/analysis/getMaskImage", { params: { imageName }, responseType: 'blob' });
      const url = URL.createObjectURL(response.data);
      setMaskImage(url);
    }
  }

  return (
    <>
      <div className="flex flex-col overflow-hidden">
        {
          useMask &&

          <div className="h-[30px] justify-start items-center flex gap-4">
            <label onClick={() => { changeMaskImage(1) }} className='font-bold' htmlFor="mask1" ><input defaultChecked={true} type="radio" id="mask1" name="mask" />기본</label>
            <label onClick={() => { changeMaskImage(2) }} className='font-bold' htmlFor="mask2" ><input type="radio" id="mask2" name="mask" />브레인</label>
            <label onClick={() => { changeMaskImage(3) }} className='font-bold' htmlFor="mask3" ><input type="radio" id="mask3" name="mask" />클라우드</label>
          </div>
        }
        <div className="flex">
          <canvas
            ref={canvasRef}
            className="w-full h-[80%]"
            width={400}
            height={320}
          />
        </div>
      </div>
    </>
  );
}