import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion'
import Timer from '../Timer/idex';
function Find() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Main.user);
  const [userData, setUserData] = useState({
    name: null,
    email: null,
  });
  const [userId, setUserId] = useState();
  const [isAuth, setIsAuth] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false); // 이메일 전송 확인
  const [isMailAuth, setIsMailAuth] = useState(false); // 입력값과 인증코드 비교
  const [count, setCount] = useState(180); // 인증 타이머
  const userDataChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    setUserData((userData) => ({
      ...userData,
      [id]: value,
    }));
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const findId = () => {
    axios({
      method: 'post',
      url: '/api/main/findId',
      data: {
        name: userData.name,
        email: userData.email,
      },
    })
      .then((response) => {
        const data = response.data;
        setUserId(data.userId);
      })
      .catch((error) => {
        toast.error('일치하는 아이디가 없습니다. 이메일을 확인해주세요.');
        setUserId('')
      });
  };

  const userCheck = () => {
    axios({
      method: 'post',
      url: '/api/main/userCheck',
      data: {
        email: userData.email,
      },
    })
      .then((response) => {
        const data = response.data.success;
        console.log('data: ', data);
        if (data == true) {
          sendAuthCode();
        } else {
          toast.error('정보를 다시 입력해주세요');
        }
      })
      .catch((error) => {
        console.error('오류 발생:', error);
      });
  };

  // 인증 코드 전송
  const sendAuthCode = () => {

    if (isSendMail) setCount(180);
    const inputEmail = userData.email;

    // 인증 코드 전송 요청
    axios
      .post('/api/v1/resetPwmailAuth', { email: inputEmail })
      .then(function (response) {
        if (response.data.data.result) {
          const data = { receiptor: inputEmail }
          const authUrl = "http://kict.ai.phase.team:3030/sendEmailCode"
          axios.post(authUrl, data, {}).then(res => {
            if (res.data.result != -1) {
              toast.success('이메일로 코드가 발송됐습니다.');
              setIsAuth(true);
              setIsSendMail(true);
            } else {
              toast.error('이메일 발송에 실패하였습니다. 관리자에게 문의해주세요.');
              setIsSendMail(false);
            }
          })

        } else {
          toast.error("이메일발송에 실패했습니다.")
          setIsSendMail(false);
        }
      })
      .catch(function (error) {
        console.error('인증 코드 전송 중 오류 발생:', error);
      });
  };

  const checkAuthCode = () => {

    const inputEmail = userData.email
    const inputAuthCode = getValues('authCode');

    if (count == 0) {
      toast.error('인증기간이 만료됐습니다.');
      setIsMailAuth(false);
      return;
    }
    // 인증 코드 전송 요청
    axios
      .post('http://kict.ai.phase.team:3030/checkMailAuth', {
        receiptor: inputEmail,
        authCode: inputAuthCode,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.isAuth == true) {
          toast.success('인증이 완료됐습니다.');
          setIsMailAuth(true);
          findId()
        }
      })
      .catch(function (error) {
        toast.error('다시 입력해주세요');
        setIsMailAuth(false);
        console.error('e:', error);
      });
  };


  return (
    <>
      <h3 className="flex items-center">
        <img src="/images/tit_icon1.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">아이디찾기</span>
      </h3>
      <article className="mt-10  contentsbox px-5">
        <ul className="grid grid-cols-1 gap-8">

          <li>
            <p className="text-lg font-bold w-28">이메일 </p>
            <p className="mt-1.5 flex flex-row">
              <input
                className={`textarea1 p-3 r5 bg-slate-50 `}
                id="email"
                name="email"
                placeholder="이메일을 작성해주세요."
                value={userData.email}
                onChange={userDataChange}
              />
              <button
                type="button"
                className={`btn ml-4 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900  ${isMailAuth ? 'hidden' : null}`}
                onClick={userCheck}
              >
                {isSendMail ? '재전송' : '코드발송'}
              </button>
            </p>
          </li>
          <motion.li initial={{ opacity: 0 }} animate={isSendMail ? { opacity: 1 } : { opacity: 0 }} transition={{ duration: 0.5 }} className={isSendMail ? '' : 'hidden'}>
            <p className="text-lg font-bold w-28">인증번호<span className="font-black text-red-600">*</span></p>
            <p className="mt-1.5 flex flex-row">
              <input
                className={`${errors.email && !isSendMail ? 'error' : ''} textarea1 p-3 r5 bg-slate-50 `}
                id="authCode"
                name="authCode"
                placeholder="인증코드"
                maxLength={6}
                {...register('authCode', {
                  required: '인증코드를 입력해주세요',
                  validate: () =>
                    isMailAuth == true ? true : '코드확인을 클릭해주세요',
                })}
              />
              <button
                type="button"
                class={isMailAuth ? 'hidden' : 'ml-4  btn bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900'}
                onClick={checkAuthCode}
              >
                코드확인
              </button>
            </p>
            <div class={isMailAuth ? 'hidden' : ''}>
              {isSendMail && <Timer count={count} setCount={setCount} />}
              {errors.authCode && <p>{errors.authCode.message}</p>}
            </div>
          </motion.li>

          <motion.li initial={{ opacity: 0 }} animate={isMailAuth ? { opacity: 1 } : { opacity: 0 }} transition={{ duration: 0.5 }}>
            <p className="text-lg font-bold w-28">아이디</p>
            <p className="mt-1.5 flex flex-row">
              <input
                type="text"
                id="id"
                name="id"
                className={`textarea1 p-3 r5 bg-slate-50 `}
                autocomplete="id"
                disabled
                value={userId}
              />
            </p>
          </motion.li>
        </ul>
      </article>
    </>
  );
}

export default Find;
