import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setUser } from '../../store/modules/Main';
import axiosClient from '../../lib/axiosClient';
import { toast } from 'react-toastify';

export default function MyPage({ }) {
  const navigate = useNavigate();
  const formRef = useRef(null)
  const user = useSelector((state) => state.Main.user);
  const dispatch = useDispatch();

  const logout = async (logoutCallback = () => { }) => {
    let result = true;
    axiosClient({
      method: 'POST',
      url: '/api/v1/logout',
    })
      .then(async (res) => {
        if (res.data.result) {
          logoutCallback?.();
          navigate('/login');
          setTimeout(() => {
            dispatch(setUser({ user: null }));
          }, 0);
        }
      })
      .catch((error) => {
        result = false;
        toast.error('다시 시도해주세요');
      });
    return result;
  };


  const userDelete = () => {
    axiosClient({
      method: 'POST',
      url: '/api/main/delete',
      data: {
        userId: user?.userId,
      },
    })
      .then((res) => {
        toast.info('회원 탈퇴가 완료됐습니다.');
        logout();
      })
      .catch((error) => {
        toast.error('다시 시도해주세요');
      });
  };

  useEffect(() => {
    if (_.isEmpty(user)) {
      toast.error('로그인후 이용하실수있습니다.');
      navigate('/');
    }
  }, []);


  return (
    <>
      <h3 className="flex items-center">
        <img src="/images/tit_icon1.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">회원정보</span>
      </h3>
      <form
        ref={formRef}
        className='flex-1'
      >
        <article className="mt-10  contentsbox px-5">
          <ul className="grid grid-cols-1 gap-8">
            <li>
              <p className="text-lg font-bold w-28">
                아이디
              </p>
              <p className={`mt-1.5 flex flex-row`}>
                <label>
                  {user?.userId}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">이메일</p>
              <p className="mt-1.5 flex flex-row">
                {user?.email}
              </p>
            </li>
          </ul>
        </article>
      </form>


      <article className="mt-8 pt-8 shrink-0 bottom-0 border-t-2 flex justify-between">
        <p className="flex  gap-1.5">
          <a
            href="javascript:void(0)"
            className="btn_h45 btn_line r5 font-bold w-52"
            onClick={() => {
              logout(() => {
                toast.success(
                  '정상적으로 로그아웃되었습니다. \n로그인 페이지로 이동합니다.',
                );
              });
            }}
          >
            로그아웃
          </a>
        </p>
        <button
          onClick={userDelete}
          className="btn_h45 btn_blue r5 font-bold w-52"
        >
          회원탈퇴
        </button>
      </article>
    </>
  );
}
