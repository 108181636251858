import EChartsReact from 'echarts-for-react';
import { useEffect, useState, useRef } from 'react';
import NetworkChartEnlarge from './NetworkChartEnlarge';

export default function NetWorkChart({ data, crowd, useTopMenu = true }) {
  const [networkChartOptions, setNetworkChartOptions] = useState({});
  const inputRef = useRef(null);
  const [keyWord, setKeyWord] = useState('');
  const [key, setKey] = useState(0);
  const [viewCountSort, setViewCountSort] = useState(-1);
  const [activateEnlarge, setActivateEnlarge] = useState(false);

  useEffect(() => {
    if (data) {
      const nodes = [];
      const links = [];
      const nodeMap = new Map(); // 노드 중복 확인용
      setKey(key + 1);
      // 노드와 링크 생성

      const slicedData =
        viewCountSort == -1 ? data : data.slice(0, viewCountSort);

      slicedData.forEach((item) => {
        if (!nodeMap.has(item.term1)) {
          nodes.push({
            name: item.term1,
            value: item.freq,
            symbolSize: Math.sqrt(item.freq),
          });
          nodeMap.set(item.term1, true);
        }
        if (!nodeMap.has(item.term2)) {
          nodes.push({
            name: item.term2,
            value: item.freq,
            symbolSize: Math.sqrt(item.freq),
          });
          nodeMap.set(item.term2, true);
        }

        // opacity 업데이트
        nodes.forEach((node) => {
          node.itemStyle = {
            opacity: keyWord
              ? node.name == keyWord ||
                slicedData.some(
                  (item) =>
                    (item.term1 == node.name && item.term2 == keyWord) ||
                    (item.term2 == node.name && item.term1 == keyWord),
                )
                ? 1
                : 0.2
              : 1,
          };
        });

        links.push({
          source: item.term1,
          target: item.term2,
          value: item.freq,
          // 링크의 스타일을 기본으로 설정
          lineStyle: {
            color: '#000',
            width: 1,
            opacity: 0.3,
          },
        });
      });

      // 강조된 노드와 연결된 링크 스타일 설정
      if (keyWord) {
        links.forEach((link) => {
          if (link.source === keyWord || link.target === keyWord) {
            link.lineStyle = {
              color: 'orange',
              width: 5,
              opacity: 1,
            };
          }
        });
      }

      setNetworkChartOptions({
        title: {},
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'node') {
              const nodeName = params.data.name;
              const connectedNodes = links.filter(
                (link) => link.source === nodeName || link.target === nodeName,
              );
              const hoverView = connectedNodes
                .map((connectedNode) => {
                  const targetNode =
                    params.name === connectedNode.target
                      ? connectedNode.source
                      : connectedNode.target;

                  return `<span style="font-weight: bold; color: red;">${params.name}</span> >
                          <span style="font-weight: bold; color: blue;">${targetNode}</span> ${connectedNode.value}`;
                })
                .join('<br>');
              return hoverView;
            } else if (params.dataType === 'edge') {
              return `<span style="font-weight: bold; color: red;">${params.data.source}</span> > <span style="font-weight: bold; color: blue;">${params.data.target}</span> ${params.data.value}`;
            }
          },
        },
        legend: {},
        series: [
          {
            layout: 'force',
            type: 'graph',
            legendHoverLink: false,
            data: nodes,
            links: links,
            roam: true,
            force: {
              layoutAnimation: false,
              repulsion: 100,
              gravity: crowd,
            },
            label: {
              show: true,
              position: 'right',
              formatter: '{b}',
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                color: '#000',
                width: 5,
              },
            },
          },
        ],
      });
    }
  }, [data, keyWord, viewCountSort]);

  useEffect(() => {
    if (inputRef.current != null) {
      inputRef.current.value = keyWord;
    }
  }, [keyWord]);
  return (
    <>
      {
        useTopMenu &&
        <div className="mt-1 flex items-center gap-1 justify-center absolute top-0" style={{ zIndex: 3, background: 'white' }}>
          <label>
            <input
              type="text"
              ref={inputRef}
              className="f_15 px-3 r5 bg-slate-50"
              placeholder="문장을 입력해주세요."
              style={{ height: '35px' }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setKeyWord(e.target.value);
                }
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            className="flex items-center justify-center btn_h35 r5 px-3 bg-indigo-950/50 hover:bg-slate-700"
            onClick={() => {
              setKeyWord(inputRef.current.value);
            }}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="10"
              className="shrink-0 imgwhite"
            />
          </a>
          <a
            href="javascript:void(0)"
            className="flex items-center justify-center btn_h35 r5 px-3 bg-indigo-950/50 hover:bg-slate-700"
            onClick={() => {
              setActivateEnlarge(true);
            }}
          >
            <img
              src="/images/icon_plus1.png"
              alt="확대"
              width="10"
              className="shrink-0 imgwhite"
            />
          </a>
          <select
            className="f_15 px-3 r5 bg-slate-50 input_h45"
            onChange={(e) => {
              setViewCountSort(e.target.value);
            }}
          >
            <option value="-1" selected={viewCountSort == 1 ? true : false}>
              전체
            </option>
            <option value="30" selected={viewCountSort == 30 ? true : false}>
              30개
            </option>
            <option value="20" selected={viewCountSort == 20 ? true : false}>
              20개
            </option>
            <option value="10" selected={viewCountSort == 10 ? true : false}>
              10개
            </option>
          </select>
        </div>
      }

      <EChartsReact
        key={`network_${key}`}
        option={networkChartOptions}
        style={{ width: '100%', height: '100%', paddingTop: `${useTopMenu ? '50px' : '0px'}` }}
      />

      {activateEnlarge && (
        <NetworkChartEnlarge
          setIsActive={setActivateEnlarge}
          keyWord={keyWord}
          setKeyWord={setKeyWord}
          viewCountSort={viewCountSort}
          setViewCountSort={setViewCountSort}
          data={data}
          crowd={crowd}
        />
      )}
    </>
  );
}
