import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosClient from '../../../../lib/axiosClient';
import { useSearchParams } from 'react-router-dom';
import useExcel from '../../../../hooks/useExcelProcessor';
import useTableControl from '../../../../hooks/useTableControl';
import ReactTable from '../../../Components/ReactTable';
import ReactTable_CS from '../../../Components/ReactTable_CS';
import Pagination from '../../../Components/Pagination';
import dayjs from 'dayjs';
import _ from 'lodash';
import { toast } from 'react-toastify';
import ModelScoreChart from '../../../Chart/ModelScoreChart';
export default function CompareModelPop({
  setIsOpen,
}) {
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  const [columns, setColumns] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = searchParams.get('history');
  const [compareChartData, setCompareChartData] = useState([])
  const getAiModelCompareData = async () => {
    axiosClient({
      method: 'post',
      url: '/api/analysis/getAiModelCompareData',
      data: { projectId, historyId },
    }).then((res) => {
      const data = res.data.data.compareData
      // let compareData = _.map(modelList, model => {
      //   return { fileName: model.fileName, calcScore: model.calcScore }
      // })
      setCompareChartData(data)
      console.log("🚀 ~ getAiModelCompareData ~ data:", data)

    });
  };

  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'index',
        size: '7%',
      },
      {
        header: '모델명',
        id: 'filename',
        textAlign: 'text-center',
      },
      {
        header: '분석대상문서',
        id: 'docnames',
        textAlign: 'text-center',

        size: '20%'
      },
      {
        header: '분석 개수',
        id: 'count',
        textAlign: 'text-center',
      },
      {
        header: '마지막 분석 일자',
        id: 'lastanalysisat',
        textAlign: 'text-center',
      },
      {
        header: '모델 생성일자',
        id: 'createdat',
        textAlign: 'text-center',
      },
    ]);

    getAiModelCompareData();
  }, []);


  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '1200px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/icon_setting1.png" width="22" />
              </span>
              <span className="tit">AI 분석모델 성능비교 </span>
            </p>
            <p className="mt-1 text-black/60">
              AI난이도 분석 시 사용되는 모델을 비교하세요.
            </p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <div id="addlist">
              <div id="tab2">
                <ul className="">
                  <li className="font-black text-lg">모델 정확도 그래프</li>
                  <p className="text-black/70">
                    재학습된 모델들의 정확도를 표시합니다.
                  </p>
                </ul>
                <div className='mt-3'>
                  <ModelScoreChart data={compareChartData} />
                </div>
              </div>
              <div className='tab2 mt-5'>
                <ul className="">
                  <li className="font-black text-lg">모델 정확도 그래프</li>
                  <p className="text-black/70">
                    재학습된 모델들의 정확도를 표시합니다.
                  </p>
                </ul>
                <div className='mt-3'>
                  <table className='mt-4 w-full'>
                    <thead className="thead1">
                      <tr className='text-center'>
                        <th className='p-2'>
                          번호
                        </th>
                        <th className='p-2'>
                          모델명
                        </th>
                        <th className='p-2'>
                          분석대상문서
                        </th>
                        <th className='p-2'>
                          분석 개수
                        </th>
                        <th className='p-2'>
                          마지막 분석일자
                        </th>
                        <th className='p-2'>
                          모델 생성일자
                        </th>

                      </tr>
                    </thead>
                    <tbody className='tbody1 f_15 text-center'>
                      {
                        compareChartData.map((data, index) => {
                          return (
                            <>
                              <tr>
                                <td className='py-1.5 px-3'>
                                  {index + 1}
                                </td>
                                <td className='py-1.5 px-3'>
                                  {data.filename}
                                </td>
                                <td className='py-1.5 px-3'>
                                  {data.docnames}
                                </td>
                                <td className='py-1.5 px-3'>
                                  {data.count}
                                </td>
                                <td className='py-1.5 px-3'>
                                  {data.lastanalysisat}
                                </td>
                                <td className='py-1.5 px-3'>
                                  {data.createdat}
                                </td>
                              </tr>
                            </>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </li>
          <li className="mt-2 shrink-0 grid grid-cols-1 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              닫기
            </a>
          </li>
        </ul>

        <div className="popbg btn_pop1"></div>
      </article>
    </>
  );
}
