import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaRegUserCircle } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import { setCurrentProject, setSelectedFileName } from '../store/modules/Main';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../lib/axiosClient';
export default function Header({ }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id') || 0;
  const projectTitle = useSelector((state) => state.Main.currentProject.title);
  const fileName = useSelector((state) => state.Main.selectedFileName);
  const location = useLocation();
  const pathName = location.pathname;
  const goHome = () => {
    if (_.includes(pathName, 'regist') || _.includes(pathName, 'find')) {
      navigate('/login')

    } else {
      dispatch(
        setCurrentProject({ project: { title: '', role: -1, status: '' } }),
      );
      dispatch(setSelectedFileName({ fileName: '' }));
      navigate('/projects');
    }
  };

  const logout = () => {
    if (confirm("로그아웃하시겠습니까?")) {
      axiosClient.get('/api/v1/logout').then(res => {
        if (res.data.result == true) {
          console.log("logout success ")
          navigate('/login')
        }
      })
    }
  }
  if (pathName == '/regist' || _.includes(pathName, 'find')) {
    let title = _.includes(pathName, 'findPw') ? '비밀번호찾기' : _.includes(pathName, 'findId') ? '아이디찾기' : '회원가입'
    return (
      <>
        <header>
          <div className=" btn_top">
            <a href="javascript:void(0)" className="bg-black" onClick={goHome}>
              <img
                src="/images/top_home.png"
                alt=""
                width="18"
                className="mb-0.5"
              />
            </a>
            <img src="/images/top_btnbg.png" alt="" />
          </div>
          <dl className="flex items-center">
            <dt className="header_tit grow pl-14">
              <div className="flex items-center">
                <h2 className="f_34 max-[1200px]:text-2xl font-bold">
                  {title}
                </h2>
              </div>
            </dt>
          </dl>
        </header>
      </>
    )
  } else {
    return (
      <>
        <header>
          <div className=" btn_top">
            <a href="javascript:void(0)" className="bg-black" onClick={goHome}>
              <img
                src="/images/top_home.png"
                alt=""
                width="18"
                className="mb-0.5"
              />
            </a>
            <img src="/images/top_btnbg.png" alt="" />
          </div>
          <dl className="flex items-center">
            <dt className="header_tit grow pl-14">
              <div className="flex items-center">
                <h2 className="f_34 max-[1200px]:text-2xl font-bold">프로젝트</h2>
                <span
                  className="px-5 max-[1200px]:px-3"
                  style={{ minHeight: '5px', minWidth: '45px' }}
                >
                  <img src="/images/top_dot.gif" alt="" />
                </span>
                <div className="">
                  <h3
                    className={`f_25 max-[1200px]:text-lg font-bold text-slate-600 ${fileName ? 'mt-6' : ''
                      }`}
                  >
                    {projectId != 0 ? projectTitle : null}
                  </h3>
                  {fileName && (
                    <div className="flex items-center">
                      <h3 className="flex items-center f_14 max-[1200px]:text-sm font-bold text-slate-400">
                        {fileName}
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </dt>
            <dd className="gnb pr-14 shrink-0">
              <button className='btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900 mr-4' onClick={logout}> 로그아웃 </button>
              <button className='btn_h35 bg-indigo-500 text-white r5 px-4 hover:bg-slate-900 mr-4' onClick={() => { navigate('/myPage') }}> 마이페이지 </button>
              <select className="select1 mr-6" style={{ height: '35px' }}>
                <option>KOR</option>
                <option>ENG</option>
              </select>
              {/* <a href="#none" className="gnb_icon mr-4">
                <span className="new"></span>
                <img src="/images/top_icon1.png" alt="알람" width="17" />
              </a>
              <a href="#none" className="gnb_icon mr-6">
                <span className="new"></span>
                <img src="/images/top_icon2.png" alt="쪽지" width="17" />
              </a> */}
              {/* <div className="gnb_profile flex_center">
                <p className="img mr-2">
                  <img src="/images/top_profile1.png" alt="" />
                </p>
                <p className="flex flex-col leading-5">
                  <span className="f_13 text-slate-500/80">관리자</span>
                  <span className="font-bold">홍길동</span>
                </p>
              </div> */}
            </dd>
          </dl>
        </header>
      </>
    );

  }
}
