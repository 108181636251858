import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Timer from '../Timer/idex';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function App() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();

  const [isSendMail, setIsSendMail] = useState(false); // 이메일 전송 확인
  const [isMailAuth, setIsMailAuth] = useState(false); // 입력값과 인증코드 비교
  const [abledId, setAbledId] = useState(false); // 사용 가능한 아이디
  const [count, setCount] = useState(180);
  const formRef = useRef(null)
  const navigate = useNavigate();
  // 아이디 중복체크
  const dublicateIdCheck = () => {
    const userId = getValues('userId');
    if (userId == null || userId == '') {
      toast.error('아이디를 입력해주세요');

      setAbledId(false);
      return;
    }
    axios
      .post('/api/main/dublicateId', { userId: userId })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          toast.success('사용 가능한 아이디입니다.');
          setAbledId(true);
          setTimeout(() => {
            setError('userId', { message: undefined });
          }, 0);
        } else if (!response.data.success) {
          toast.error('중복된 아이디입니다.');
          setAbledId(false);
        }
      })
      .catch(function (error) {
        console.log('e:', error);
      });
  };
  // 인증 코드 전송
  const sendAuthCode = () => {
    setCount(180);
    const inputEmail = getValues('email');
    const emailValidate = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;

    if (emailValidate.test(inputEmail)) {
      // 인증 코드 전송 요청
      axios
        .post('/api/v1/mailAuth', { email: inputEmail })
        .then(function (response) {
          if (!response.data.data.isMailDublicated) {
            const data = { receiptor: inputEmail }
            const authUrl = "http://kict.ai.phase.team:3030/sendEmailCode"
            axios.post(authUrl, data, {}).then(res => {
              if (res.data.result != -1) {
                toast.success('인증코드가 전송됐습니다.');
                setIsSendMail(true);
                setTimeout(() => {
                  setError('email', { message: undefined });
                }, 0);
              } else {
                toast.error('이메일 발송에 실패하였습니다. 관리자에게 문의해주세요.');
                setIsSendMail(false);
              }
            })

          } else if (response.data.data.isMailDublicated) {
            toast.error('이미 사용중인 이메일입니다.');
            setIsSendMail(false);
          }
        })
        .catch(function (error) {
          console.error('인증 코드 전송 중 오류 발생:', error);
        });
    } else {
      setIsSendMail(false);
      toast.error('이메일 형식에 맞춰 입력해주세요');
    }
  };

  //인증코드 확인
  const checkAuthCode = () => {
    const inputEmail = getValues('email');
    const inputAuthCode = getValues('authCode');

    if (count == 0) {
      toast.error('인증기간이 만료됐습니다.');
      setIsMailAuth(false);
      return;
    }
    // 인증 코드 전송 요청
    axios
      .post('http://kict.ai.phase.team:3030/checkMailAuth', {
        receiptor: inputEmail,
        authCode: inputAuthCode,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.isAuth == true) {
          toast.success('인증이 완료됐습니다.');
          setIsMailAuth(true);
        }
      })
      .catch(function (error) {
        toast.error('다시 입력해주세요');
        setIsMailAuth(false);
        console.error('e:', error);
      });
  };

  const onsubmit = async (data) => {
    console.log('🚀 ~ onsubmit ~ data:', data);
    try {
      const response = await axios.post('/api/main/regist', data);
      console.log(response);
      if (response.data.success) {
        toast.success('회원가입이 성공적으로 완료되었습니다.');
        navigate('/login')
      }
    } catch (error) {
      console.log('🚀 ~ onsubmit ~ error:', error);
    }
  };

  return (
    <>
      <h3 className="flex items-center">
        <img src="/images/tit_icon1.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">회원가입</span>
      </h3>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onsubmit)}
        style={{ overflowY: 'auto' }}
      >
        <article className="mt-10  contentsbox px-5">
          <ul className="grid grid-cols-1 gap-8">
            <li>
              <p className="text-lg font-bold w-28">
                아이디
                <span className="font-black text-red-600">*</span>
              </p>
              <p className={`mt-1.5 flex flex-row`}>
                <label>
                  <input
                    type="text"
                    className={`${errors.userId && !abledId ? 'error' : ''} input_h45 f_15 px-3 r5 bg-slate-50 `}
                    id="userId"
                    name="userId"
                    placeholder="아이디"
                    {...register('userId', {
                      required: {
                        value: !abledId, // 필수 입력 여부
                        message: '필수입력 항목입니다.',
                      },
                      validate: (value) => {
                        if (!abledId) {
                          return '아이디 중복체크를 진행해주세요';
                        }

                        return true; // 검증이 통과한 경우
                      },
                      onChange: (e) => {
                        setAbledId(false)
                      }
                    })}
                  />
                </label>
                <button
                  class={abledId ? 'hidden' : 'btn ml-4 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900'}
                  type="button"
                  onClick={dublicateIdCheck}
                >
                  중복체크
                </button>
              </p>
              {errors.userId && <p>{errors.userId.message}</p>}
            </li>
            <li>
              <p className="text-lg font-bold w-28">비밀번호<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className={`${errors.password ? 'error' : ''} input_h45 f_15 px-3 r5 bg-slate-50 `}
                    id="password"
                    name="password"
                    placeholder="비밀번호"
                    {...register('password', {
                      required: '필수입력 항목입니다.',
                      // validate: (value) =>
                      //   /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/.test(value)
                      //     ? true
                      //     : '비밀번호를 확인해주세요',
                    })}
                  />
                  {errors.password && <p>{errors.password.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">비밀번호확인<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5">
                <label>
                  <input
                    className={`${errors.pwCheck ? 'error' : ''} input_h45 f_15 px-3 r5 bg-slate-50 `}
                    id="pwCheck"
                    name="pwCheck"
                    type="password"
                    placeholder="비밀번호 확인"
                    {...register('pwCheck', {
                      required: '필수입력 항목입니다.',
                      validate: (value) =>
                        value == watch('password')
                          ? true
                          : '비밀번호가 같지 않습니다.',
                    })}
                  />
                  {errors.pwCheck && <p>{errors.pwCheck.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">이메일<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5 flex flex-row">
                <input
                  className={`${errors.email && !isSendMail ? 'error' : ''} textarea1 p-3 r5 bg-slate-50 `}
                  id="email"
                  name="email"
                  placeholder="이메일"
                  {...register('email', {
                    required: '필수입력 항목입니다.',
                    validate: (value) => {
                      const isEmailValid =
                        /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/.test(
                          value,
                        );
                      if (!isEmailValid) {
                        return '이메일 형식을 확인해주세요';
                      }

                      // 메일 인증 검사
                      if (!isSendMail) {
                        return '코드 전송을 클릭해주세요';
                      }

                      return true;
                    },
                    onChange: () => {
                      setIsMailAuth(false)
                      setIsSendMail(false)
                      setValue("authCode", '')
                    }
                  })}
                />
                <button
                  type="button"
                  className={`btn ml-4 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900  ${isMailAuth ? 'hidden' : ''}`}
                  onClick={sendAuthCode}
                >
                  {isSendMail ? '재전송' : '인증코드전송'}
                </button>
              </p>
              {errors.email && !isSendMail && <p>{errors.email.message}</p>}
            </li>
            <li className={isSendMail ? '' : 'hidden'}>
              <p className="text-lg font-bold w-28">인증번호<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5 flex flex-row">
                <input
                  className={`${errors.email && !isSendMail ? 'error' : ''} textarea1 p-3 r5 bg-slate-50 `}
                  id="authCode"
                  name="authCode"
                  placeholder="인증코드"
                  maxLength={6}
                  {...register('authCode', {
                    required: '인증코드를 입력해주세요',
                    validate: () =>
                      isMailAuth == true ? true : '코드확인을 클릭해주세요',
                  })}
                />
                <button
                  type="button"
                  class={isMailAuth ? 'hidden' : 'ml-4  btn bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900'}
                  onClick={checkAuthCode}
                >
                  코드확인
                </button>
              </p>
              <div class={isMailAuth ? 'hidden' : ''}>
                {isSendMail && <Timer count={count} setCount={setCount} />}
                {errors.authCode && <p>{errors.authCode.message}</p>}
              </div>
            </li>
            <li>
              <button type='submit' className='btn h-[45px] bg-indigo-600 text-white r5 px-4 hover:bg-slate-900'>
                회원가입
              </button>
            </li>
          </ul>
        </article>
      </form>

    </>
  );
}

export default App;
