import { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import axiosClient from "../../../lib/axiosClient";
import ReactTable_CS from "../../Components/ReactTable_CS";
import useTableControl from "../../../hooks/useTableControl";
import Neo4jGraph from "../../Components/Neo4jGraph";
import BasicAnalysisReport from "./BasicAnalysisReport";
import dayjs from "dayjs";
export default function Report({ }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const contentsRefs = useRef({});
  const redisKey = searchParams.get("redisKey")
  const historyId = parseInt(searchParams.get('history'));
  const projectId = parseInt(searchParams.get('id'));
  const [difficultyList, setDifficultyList] = useState([])
  const [ontologyList, setOntologyList] = useState([])
  const [faResult, setFaResult] = useState(undefined);
  const [saResult, setSaResult] = useState(undefined);
  const [naResult, setNaResult] = useState(undefined);
  const [networkK, setNetworkK] = useState(0.7);
  const [projectDetail, setProjectDetail] = useState({})
  const [fileName, setFileName] = useState('');
  const [fetched, setFetched] = useState(false);
  const [contents, setContents] = useState([
    { title: '프로젝트 정보', page: 3 },
    { title: '기본분석 결과', page: 3 },
    { title: 'AI 기반 입찰문서 문장 난이도 분석 결과', page: 1 },
    { title: '온톨로지 기반 난이도 해석 결과', page: 1 },
  ]);
  const getReportData = async () => {
    const response = await axiosClient.get(`http://kict.ai.phase.team:3030/getReportData`, { params: { uuid: redisKey, historyId, projectId } })
    const reportData = response.data || {}
    if (reportData != false) {

      setDifficultyList(reportData?.difficulty || [])
      setOntologyList(reportData?.ontology || [])
      setFaResult(reportData.faResult);
      setSaResult(reportData.saResult);
      setNaResult(reportData.naResult);
      setNetworkK(reportData.networkK);
      setProjectDetail(reportData.projectDetail)
      setFileName(reportData.fileName)
      setFetched(true)
    }
    window.redisKey = redisKey
  }

  useEffect(() => {
    getReportData()
  }, [])

  // 화면 DPI를 동적으로 계산하는 함수
  const getDPI = () => {
    const div = document.createElement("div");
    div.style.width = "1in";  // CSS에서 1인치의 기준을 이용
    div.style.height = "1in";
    div.style.position = "absolute";
    div.style.top = "-100%";
    document.body.appendChild(div);

    // 실제 px 단위로 1인치의 길이를 측정
    const dpi = div.offsetWidth;
    document.body.removeChild(div);
    return dpi;
  };

  // cm를 px로 변환하는 함수, 동적 DPI를 사용
  const cmToPx = (cm, dpi) => cm * (dpi / 2.54);

  useEffect(() => {
    if (fetched) {

      const dpi = getDPI();  // 현재 화면의 DPI를 가져옴
      const targetHeightCm = 29.7;
      const targetHeightPx = Math.ceil(cmToPx(targetHeightCm, dpi));

      if (contentsRefs.current != null) {
        let copiedContents = _.cloneDeep(contents)
        Object.keys(contentsRefs.current).forEach((key, i) => {
          const content = contentsRefs.current[key]
          console.log("🚀 ~ Object.keys ~ content:", content)
          if (content) {
            // 현재 content 높이 가져오기
            const currentHeight = content.offsetHeight;
            // 29.7cm를 넘는지 확인 후 올림 계산
            // if (currentHeight > targetHeightPx) {
            let multiplier = Math.ceil(currentHeight / targetHeightPx);
            if (i != 2) {
              if (i == 3) {
                ontologyList.forEach(ontology => {
                  multiplier = multiplier + 3;
                  _.forEach(ontology.resultdata, data => {
                    multiplier = multiplier + 3;
                  })
                })
              } else {
                multiplier = multiplier + 1
              }
            }
            const newHeight = multiplier * targetHeightPx;

            // 새로운 높이를 px로 content에 설정
            content.style.height = `${newHeight}px`;
            let next = contents[i + 1]
            if (!_.isEmpty(next)) {
              next.page = contents[i].page + multiplier
              copiedContents[i + 1] = next
            }
            // } else {
            //   content.style.height = `${currentHeight}px`
            //   let next = contents[i + 1]
            //   if (!_.isEmpty(next)) {
            //     next.page = contents[i].page + 1
            //     copiedContents[i + 1] = next
            //   }
            // }

          }

        })

        setContents(copiedContents)

      }
    }
  }, [fetched])

  const statusOptions = {
    0: '입찰문서 작성 중',
    1: '입찰문서 작성 완료',
    10: '분석 완료',
  };

  if (!_.isEmpty(projectDetail)) {
    return (
      <>


        <article style={{ display: 'flex', justifyContent: 'center' }} >
          <div class="pop_a4size_cus" style={{ width: '21cm' }}>
            <div class="a4box" style={{ position: 'static' }} >
              <ul style={{ height: 'auto' }}>
                <li class="page1" style={{ height: '29.7cm' }}  >
                  <dl class="p-14">
                    <dd class="text-right">
                      <img src="/images/a4logo1.png" alt="" class="inline-block" />
                    </dd>
                    <dt class="pt-44 text-center">
                      <img src="/images/a4logo2.png" alt="" class="inline-block" />

                      <p class="mt-4 text-3xl font-medium ">
                        AI 기반 입찰문서<br />
                        분석 결과 리포트
                      </p>
                    </dt>
                    <dd class="pt-20 flex justify-center grow">
                      <div>
                        <p class="flex items-center">
                          <span class="listpoint1 w-28 px-1 shrink-0">평가일자</span>
                          <span class="ml-3 cblue1">2024년 10월 28일</span>
                        </p>
                        <p class="mt-0.5 flex items-center">
                          <span class="listpoint1 w-28 px-1 shrink-0">평가 프로젝트</span>
                          <span class="ml-3 cblue1">{projectDetail.project_name}</span>
                        </p>
                        <p class="mt-0.5 flex items-center">
                          <span class="listpoint1 w-28 px-1 shrink-0">평가 문서명</span>
                          <span class="ml-3 cblue1">{fileName}</span>
                        </p>
                      </div>
                    </dd>
                    <dd class="text-xl text-center grow">
                      한국기술연구원
                    </dd>
                  </dl>
                </li>
                <li class="page2" style={{ height: '29.7cm' }}>
                  <dl class="pt-14 px-14 pb-4">
                    <dd class="text-right">
                      <img src="/images/a4logo2.png" alt="" width="80" class="inline-block" />
                    </dd>
                    <dt class="pt-16 text-4xl font-medium">목차</dt>
                    <dd class="pt-10 grow">
                      <ul class="listbox flex flex-col gap-3">
                        {
                          contents.map((v, i) => {
                            return (
                              <>
                                <li>
                                  <p class="bg-white font-medium cblue1 pr-2">
                                    {i + 1}. {v.title}
                                  </p>
                                  <p class="pl-3 bg-white text-sm text-right">{v.page}</p>
                                </li>
                              </>
                            )
                          })
                        }
                      </ul>
                    </dd>
                    {/* <dd class="text-center text-sm font-bold">- 2 - </dd> */}
                  </dl>
                </li>
                {
                  contents.map((v, i) => {
                    return (
                      <>
                        <li class=" page3" ref={(ref) => {
                          if (ref) {  // ref가 null이 아닐 때만 업데이트
                            contentsRefs.current[`ref_${i}`] = ref;
                          }
                        }} style={{ minHeight: '29.7cm', height: 'auto' }}>
                          <dl style={{ padding: '1cm' }}>
                            <dt>
                              <p><img src="/images/a4logo2.png" alt="" width="80" /></p>
                              <p class="mt-6 text-2xl font-medium tracking-tighter">{i + 1}. {v.title}</p>
                            </dt>
                            <dd class="page_contents grow mt-3 mb-3 p-5" style={{ overflow: 'visible' }}>
                              {
                                i == 1 ? (
                                  <>
                                    <BasicAnalysisReport faResult={faResult} saResult={saResult} naResult={naResult} networkK={networkK} />
                                  </>
                                ) :
                                  i == 2 ? (
                                    <Difficulty difficultyList={difficultyList} setDifficultyList={setDifficultyList} />
                                  ) : i == 3 ? (
                                    <Ontology ontologyList={ontologyList} setOntologyList={setOntologyList} />
                                  ) :

                                    (
                                      <>
                                        {
                                          !_.isEmpty(projectDetail) &&
                                          <ul class="grid grid-cols-1 gap-1">
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12 shrink-0">프로젝트명</span>
                                              <span class="pt-1.5 ml-3">{projectDetail.project_name}</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">발주처</span>
                                              <span class="pt-1.5 ml-3">{projectDetail.client}</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">사업명</span>
                                              <span class="pt-1.5 ml-3">{projectDetail.biz_name}</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">입찰 방식</span>
                                              <span class="pt-1.5 ml-3">{projectDetail?.bid_type == 1
                                                ? '직접입찰'
                                                : projectDetail?.bid_type == 0
                                                  ? '전자입찰'
                                                  : '미선택'}</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">사업 금액</span>
                                              <span class="pt-1.5 ml-3">{projectDetail.total_budget.toLocaleString()}원</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">제출기한</span>
                                              <span class="pt-1.5 ml-3">{dayjs(projectDetail.deadline).format('YYYY-MM-DD')}</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">프로젝트 구성원</span>
                                              <span class="pt-1.5 ml-3">{projectDetail.countmembers}명</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">프로젝트 상태</span>
                                              <span class="pt-1.5 ml-3">{statusOptions[projectDetail.status || 0]}</span>
                                            </li>
                                            <li class="flex">
                                              <span class="listpoint2 w-4/12  shrink-0">프로젝트 설명</span>
                                              <span class="pt-1.5 ml-3">{projectDetail.project_desc}</span>
                                            </li>
                                          </ul>
                                        }
                                      </>
                                    )
                              }
                            </dd>
                            {/* <dd class="text-center text-sm font-bold">- 3 - </dd> */}
                          </dl>
                        </li>

                      </>
                    )
                  })
                }
                <li class=" page3" style={{ minHeight: '29.7cm', height: '29.7cm' }}>
                  <dl style={{ padding: '1cm' }}>
                    <dd class="page_contents grow mt-3 mb-3 p-5" style={{ overflow: 'visible' }}>
                      <div className='mt-5'>
                        <dl>
                          <dt class="icon1 pl-3.5 font-bold">작업의 기술 요구 수준</dt>
                          <dd class="mt-1 border p-4 flex flex-col gap-1 r5">
                            <p class="flex">
                              <span class="tdttbox px-2 bg-red-600/60">상</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                BIM 설계에 투입되는 전체 자원(Resource)의 10% 이상 20% 미만
                              </span>
                            </p>
                            <p class="flex">
                              <span class="tdttbox px-2 bg-lime-600/60">중</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                BIM 설계에 투입되는 전체 자원(Resource)의 20% 이상
                              </span>
                            </p>
                            <p class="flex">
                              <span class="tdttbox px-2 bg-sky-600/60 ">하</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                BIM 설계에 투입되는 전체 자원(Resource)의 10% 미만
                              </span>
                            </p>
                          </dd>
                        </dl>
                        <dl class="mt-6">
                          <dt class="icon1 pl-3.5 font-bold">비용&인력 투입 수준</dt>
                          <dd class="mt-1 border p-4 flex flex-col gap-1 r5">
                            <p class="flex">
                              <span class="tdttbox px-2 bg-red-600/60">상</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                복잡하고 정교한 작업으로, 고도의 전문 지식과 풍부한 경험이 필수적임
                              </span>
                            </p>
                            <p class="flex">
                              <span class="tdttbox px-2 bg-lime-600/60">중</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                중간 정도의 복잡성을 가지며, 일정 수준의 경험과 전문 지식이 요구됨
                              </span>
                            </p>
                            <p class="flex">
                              <span class="tdttbox px-2 bg-sky-600/60 ">하</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                단순하고 반복적인 작업으로, 특별한 기술이나 지식 없이도 충분히 수행 가능
                              </span>
                            </p>
                          </dd>
                        </dl>
                        <dl class="mt-6">
                          <dt class="icon1 pl-3.5 font-bold">협업 및 협의 요구 수준</dt>
                          <dd class="mt-1 border p-4 flex flex-col gap-1 r5">
                            <p class="flex">
                              <span class="tdttbox px-2 bg-red-600/60">상</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                여러 팀, 부서 또는 외부 기관과 지속적인 협업이 필수적이며, 복잡한 의사결정과 조정이 필요함.
                              </span>
                            </p>
                            <p class="flex">
                              <span class="tdttbox px-2 bg-lime-600/60">중</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                작업을 완료하기 위해 다른 부서와 주기적인 협업과 조율이 필요함. 다른 팀이나 부서와의 일정 조율이 요구됨
                              </span>
                            </p>
                            <p class="flex">
                              <span class="tdttbox px-2 bg-sky-600/60 ">하</span>
                              <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                                대부분 독립적으로 수행할 수 있으며, 다른 팀이나 부서와의 협업이 필요하지 않음. 단일 팀 내에서 이루어지는 작업
                              </span>
                            </p>
                          </dd>
                        </dl>
                      </div>
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>



        </article>
      </>
    )
  } else return (
    <>
      PDF 생성에 실패했습니다. 관리자에게 문의해주세요.
    </>
  )

}




const Difficulty = ({ difficultyList, setDifficultyList }) => {

  const [columns, setColumns] = useState([
    {
      header: '번호',
      id: 'index',
      size: '13%',
    },
    {
      header: '문장',
      id: 'sentence',
      textAlign: 'text-left',
      cellRender: (row) => {
        if (row.difficulty == 'HIGH') {
          return <span className="text-red-600">{row.sentence}</span>;
        } else if (row.difficulty == 'MID') {
          return <span className="text-blue-600">{row.sentence}</span>;
        } else {
          return <span className="">{row.sentence}</span>;
        }
      }
    },
    {
      header: '난이도',
      id: 'difficulty',
      cellRender: (row) => {
        if (row.difficulty == 'HIGH') {
          return <span className="tdttbox px-2 bg-red-600/70">상</span>;
        } else if (row.difficulty == 'MID') {
          return <span className="tdttbox px-2 bg-lime-600/60">중</span>;
        } else {
          return <span className='tdttbox px-2 bg-sky-600/60'>하</span>;
        }
      },
      size: '15%',
    }
  ]);
  const {
    pageInfo,
    onSortingChange,
  } = useTableControl({ setColumns, pageSize: 99999 });
  const [totalCount, setTotalCount] = useState(difficultyList?.length);


  return (
    <>
      <ReactTable_CS
        className="mt-4 w-full"
        columns={columns}
        data={difficultyList}
        setData={setDifficultyList}
        totalCount={totalCount}
        onSortingChange={onSortingChange}
        pageInfo={pageInfo}
        showPagination={false}
      />
    </>
  )
}
const Ontology = ({ ontologyList }) => {
  return (
    <>
      {
        ontologyList.map((ontology, index) => {
          const resultData = ontology.resultdata
          return (
            <>
              <div style={{ pageBreakAfter: 'always', }} >
                <dl className={index > 0 ? 'mt-20' : ''}>
                  <h4 className="flex items-center">
                    <img src="/images/bullet_icon1.png" alt="icon" width="10" />
                    <span className="ml-1.5 f_22 font-bold">대상문장</span>
                  </h4>
                  <dd className="mt-2 flex items-center gap-1">
                    <label className="grow">
                      {ontology.sentence}
                    </label>
                  </dd>
                </dl>

                <dl className={`${index > 0 ? 'mt-10' : 'mt-3'}`}>
                  <dt>
                    <p className="text-lg font-bold">온톨로지 기반 문장 난이도 분석결과</p>
                    <p className="mt-2 text-black/80">- 사전에 전문가 인터뷰 등을 통해 구축된 난이도 온톨로지를 활용하여 문장별 난이도에 대한 해석 제공
                    </p>
                    <p className="mt-2 text-black/80">- 온톨로지의 해석은 작업요구, 비용&인력, 협업/협의 분야별 난이도 분석 결과 및 그래프 제공
                    </p>
                    <p className="mt-2 text-black/80">- 해당 결과물은 주요 문장에 대한 상세분석자료로 활용가능
                    </p>
                  </dt>
                  <dd className="mt-2  bg-slate-100/80 p-4 r5 text-sm text-black/60 leading-6">
                    <dd className=" text-sm text-black/60 leading-6" dangerouslySetInnerHTML={{ __html: ontology.condition }}></dd>
                    해당 문장의 주요 BIM 기술 키워드는 <span className="font-extrabold text-base">' {ontology.keywords} '</span>입니다.
                    키워드 별 업무 프로세스 및 관련 작업, 요구 시술 수준은 아래 표와 같습니다.<br />
                    따라서 해당 문장의 AI 분석결과는 <span className="font-extrabold text-indigo-700 text-base">난이도
                      (상/중/하)</span>로
                    평가되었습니다.
                  </dd>
                </dl>
                {
                  resultData.length > 0 && resultData.map((selectedData, i) => {
                    const keyword = selectedData[0]['Task']
                    const cypherQuery = `MATCH (w {name : '${keyword}'})
                                      MATCH (w)-[r]->(p)
                                      RETURN w, p, r`
                    const complexity = selectedData[0]['complexity']
                    const coordination = selectedData[0]['coordination']
                    const resource = selectedData[0]['resource']
                    return (
                      <>
                        <dl className="mt-20" style={{ pageBreakBefore: `${i == 0 ? '' : 'always'}` }}>
                          <ul className="flex items-stretch gap-10 " style={{ alignContent: 'flex-start', flexDirection: 'column', flexWrap: 'wrap' }}>
                            <li className="grow">
                              <div className="flex justify-between flex-col">
                                <h4 className="flex items-center">
                                  <img src="/images/bullet_icon1.png" alt="icon" width="10" />
                                  <span className="ml-1.5 f_22 font-bold">키워드 별 업무 프로세스</span>
                                </h4>
                                <ul className="flex mt-5 items-stretch gap-5" style={{ alignContent: 'flex-start', flexDirection: 'column', flexWrap: 'wrap' }}>
                                  <li>
                                    주요 키워드 :  {<strong> {selectedData[0]['Task']}</strong>}
                                  </li>
                                  <li>
                                    작업요구 기술수준 : {<span className="tdttbox px-2 bg-red-600/70">{complexity}</span>}
                                  </li>
                                  <li>
                                    비용&인력 투입 수준 :  <span className="tdttbox px-2 bg-lime-600/60">{resource}</span>
                                  </li>
                                  <li>
                                    요구 작업순서 :  <span className="tdttbox px-2 bg-sky-600/70 ">{coordination}</span>
                                  </li>
                                </ul>
                              </div>
                              <table className="mt-4 w-full ">
                                <colgroup>
                                  <col width="12%" />
                                  <col width="*" />
                                  <col width="12%" />
                                </colgroup>
                                <thead className="thead1">
                                  <tr className="text-center tracking-tighter">
                                    <th className="p-2">요구 작업순서</th>
                                    <th className="p-2">상세설명</th>
                                    <th className="p-2">관련 작업</th>
                                  </tr>
                                </thead>
                                <tbody className="tbody1 f_15 text-center tracking-tighter">
                                  {
                                    selectedData.length > 0 ? (
                                      <>
                                        {
                                          selectedData[1]['detail'].map((data, index) => {
                                            const detail = data
                                            const process = selectedData[1]['process'][index]
                                            const relatedWork = selectedData[1]['related_work'][index]
                                            return (
                                              <>
                                                <tr>
                                                  <td className="py-2.5 px-5">
                                                    <span className="text-indigo-950/70 font-extrabold">{process}</span>
                                                  </td>
                                                  <td className="py-2.5 px-5 text-left">
                                                    {detail}
                                                  </td>
                                                  <td>{relatedWork}</td>
                                                </tr>
                                              </>
                                            )
                                          })
                                        }
                                      </>
                                    ) : (
                                      <>
                                        <tr>
                                          <td colSpan={7}>
                                            선택가능한 키워드가 존재하지않습니다.
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  }

                                </tbody>
                              </table>
                            </li>


                          </ul>
                          <li className=" shrink-0 flex flex-col" style={{ width: '100%', height: '30cm', pageBreakBefore: 'always' }}>
                            <h4 className=" mt-20 flex items-center">
                              <img src="/images/bullet_icon1.png" alt="icon" width="10" />
                              <span className="ml-1.5 f_22 font-bold">[{selectedData[0]['Task']}]  온톨로지 그래프 </span>
                            </h4>
                            <div className="mt-4 border  p-5">
                              {
                                _.isEmpty(cypherQuery) ? (
                                  <>
                                    선택가능한 키워드가 존재하지않습니다.
                                  </>
                                ) : (
                                  <>
                                    <Neo4jGraph useControl={false} query={cypherQuery} isTask={true} id={`viz_${index}_${i}`} height={'600px'} fromPDF={true} />
                                  </>
                                )
                              }
                            </div>
                            <p className="mt-2 icon1 pl-3">선택되는 task에 따라 표출되는 온톨로지 그래프</p>
                            {/* <div style={{ height: '600px', width: '100%', }}></div> */}
                            {/* <div style={{ height: '800px' }}></div> */}
                          </li>
                        </dl>
                      </>
                    )
                  })
                }
              </div>
            </>
          )
        })
      }

    </>
  )
}