import _ from 'lodash';
// 액션 타입 정의
const UPDATE_URL = 'main/UPDATE_URL';
const SET_USER = 'main/SET_USER';
const SET_CURRENT_PROJECT = 'main/SET_CURRENT_PROJECT';
const SET_SELECTED_FILE_NAME = 'main/SET_SELECTED_FILE_NAME';
const SET_ANALYSIS_TAB = 'main/SET_ANALYSIS_TAB';
const SET_REPORT_DATA = 'main/SET_REPORT_DATA'
// 액션 생섬함수 정의
export const setUrl =
  ({ url }) =>
    (dispatch, getState) => {
      dispatch({ type: UPDATE_URL, url });
    }; //초기화

export const setUser =
  ({ user }) =>
    (dispatch, getState) => {
      dispatch({ type: SET_USER, user });
    };

export const setCurrentProject = ({ project }) => ({
  type: SET_CURRENT_PROJECT,
  project,
});

export const setSelectedFileName = ({ fileName }) => ({
  type: SET_SELECTED_FILE_NAME,
  fileName,
});

export const setAnalysisTab = ({ analysisTab }) => ({
  type: SET_ANALYSIS_TAB,
  analysisTab,
});

export const setReportData = ({ data }) => ({ type: SET_REPORT_DATA, data })

// **** 초기상태 정의
const savedState = JSON.parse(localStorage.getItem('appState'));

const initialState = savedState || {
  url: '/', //현재 주소
  user: null,
  currentProject: { title: '', role: -1, status: '' },
  selectedFileName: '',
  analysisTab: 0,
  reportData: {
    difficulty: [],
    ontology: []
  }
};

// **** 리듀서 작성
export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case UPDATE_URL: {
      let { url } = state;
      url = action.url;

      newState = {
        ...state,
        url,
      };
      break;
    }
    case SET_USER: {
      newState = {
        ...state,
        user: action.user,
      };
      break;
    }
    case SET_CURRENT_PROJECT: {
      localStorage.setItem('currentProject', JSON.stringify(action.project));
      newState = {
        ...state,
        currentProject: action.project,
      };
      break;
    }
    case SET_SELECTED_FILE_NAME: {
      newState = {
        ...state,
        selectedFileName: action.fileName,
      };
      break;
    }
    case SET_ANALYSIS_TAB: {
      newState = {
        ...state,
        analysisTab: action.analysisTab,
      };
      break;
    }
    case SET_REPORT_DATA: {
      newState = {
        ...state,
        reportData: action.data
      }
    }

    default: {
      newState = state;
    }
  }
  localStorage.setItem('appState', JSON.stringify(newState));

  return newState;
}
