import { React, useEffect, useState } from 'react';
import axios from 'axios';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { setCurrentProject } from '../../store/modules/Main';
import { useSelector, useDispatch } from 'react-redux';
import useTableControl from '../../hooks/useTableControl';
import Pagination from '../Components/Pagination';
import axiosClient from '../../lib/axiosClient';
export default function App() {
  const dispatch = useDispatch();
  const analysisTab = useSelector(
    (state) => state.Main.analysisTab,
  ); /* 왼쪽 사이드바 0:분석중, 1:분석완료 */
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns });
  const [totalCount, setTotalCount] = useState(0);
  const loginUserData = useSelector((state) => state.Main.user);
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState();

  const getProjectList = () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getIncludedProjects',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectName: getSearchOptionValue('projectName') || null,
        },
      },
    })
      .then(async (result) => {
        let filteredProjects = [];
        if (analysisTab == 1) {
          filteredProjects = result?.data?.data?.filter(
            (project) => project.status == '10',
          );
        } else if (analysisTab == 0) {
          filteredProjects = result?.data?.data?.filter(
            (project) => project.status != '10',
          );
        }
        setProjectList(filteredProjects);
        setTotalCount(filteredProjects?.length);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };
  useEffect(() => {
    getProjectList();
  }, [pageInfo, analysisTab]);

  return (
    <>
      <h3 className="flex items-center">
        <img src="/images/tit_icon1.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">프로젝트 목록</span>
      </h3>

      <article className="mt-8 contentsbox px-5">
        <div className="flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              placeholder="프로젝트명을 입력해주세요."
              style={{ width: '350px' }}
              value={getSearchOptionValue('projectName')}
              onChange={(e) => {
                setSearchOptionValue('projectName', e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  getProjectList()
                }
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={() => {
              getProjectList();
            }}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>

        <ul className="mt-6 card_list grid grid-cols-6 max-[1680px]:grid-cols-5 max-[1350px]:grid-cols-4 max-[1024px]:grid-cols-3 gap-4">
          {projectList?.map((projectData) => {
            return (
              <>
                <li
                  style={{ minHeight: '300px' }}
                  class="items_ttbox"
                  onClick={() => {
                    const role = _.find(loginUserData.includedProjectList, {
                      projectId: projectData.projectId,
                    }).role;
                    dispatch(
                      setCurrentProject({
                        project: {
                          title: projectData.projectName,
                          role,
                          status: projectData.status,
                        },
                      }),
                    );
                    navigate(
                      `/projects/projectDetails?id=${projectData.projectId}`,
                    );
                  }}
                >
                  <dl>
                    <dt class="py-6 grow">
                      <p class="tit1 f_22 px-5">{projectData.projectName}</p>
                      <p class="mt-4 data px-4">
                        {projectData.createdAt.split(' ')[0]}
                      </p>
                    </dt>
                    <dd class=" mt-3 tt2 py-4 font-bold">
                      {projectData.client}
                    </dd>
                  </dl>
                </li>
              </>
            );
          })}
          {
            analysisTab == 0 &&

            <li
              className="items_plus"
              style={{ minHeight: '300px' }}
              onClick={() => {
                navigate('/projects/newProject');
              }}
            >
              <p className="plus">
                <img src="/images/icon_plus1.png" alt="plus" width="15" />
              </p>
              <p className="mt-1 text-lg tt_orange1 font-bold">
                신규 프로젝트 생성
              </p>
            </li>
          }
        </ul>
        <Pagination
          currentPage={pageInfo.currentPage}
          onPageChange={onPageChange}
          pageSize={pageInfo.pageSize}
          totalCount={totalCount}
        />
      </article>
    </>
  );
}
