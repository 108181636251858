import axiosClient from "../../../lib/axiosClient";
import { useEffect, useState, useRef } from "react";
import NetWorkChart from "../../Chart/NetworkChart";
import BarChart from "../../Chart/BarChart";
import WordCloudChart from "../../Chart/WordCloud";
import { useSearchParams } from "react-router-dom";
export default function BasicAnalysisReport({ faResult, saResult, naResult, networkK }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const ResultTable = ({ step }) => {
    if (step == 'faStep') {
      return (
        <>
          <table className="w-4/12">
            <colgroup>
              <col width="10%" />
              <col width="*" />
              <col width="*" />
            </colgroup>
            <thead className="thead2">
              <tr className="f_15 text-center">
                <th className="py-2 px-2.5">순위</th>
                <th className="px-2.5">단체</th>
                <th className="px-2.5">빈도</th>
              </tr>
            </thead>
            <tbody className="tbody1 text-sm text-center">
              {faResult?.slice(0, 10)?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td className="p-2.5">{index + 1}</td>
                      <td className="p-2.5">{result.word}</td>
                      <td className="p-2.5">
                        <span className="point_bar">
                          {result.frequency.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          {/* <table className="w-4/12">
            <colgroup>
              <col width="100" />
              <col width="*" />
              <col width="*" />
            </colgroup>
            <thead className="thead2">
              <tr className="f_15">
                <th className="py-2 px-2.5">순위</th>
                <th className="px-2.5">단체</th>
                <th className="px-2.5">빈도</th>
              </tr>
            </thead>
            <tbody className="tbody1 text-sm text-center">
              {faResult?.slice(5, 10)?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td className="p-2.5">{index + 1 + 5}</td>
                      <td className="p-2.5">{result.word}</td>
                      <td className="p-2.5">
                        <span className="point_bar">
                          {result.frequency.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table> */}
        </>
      );
    } else {
      return (
        <>
          <table class="w-4/12">
            <colgroup>
              <col width="10%" />
              <col width="*" />
            </colgroup>
            <thead class="thead2">
              <tr class="f_15 text-center">
                <th class="py-2 px-2.5">순위</th>
                <th class="px-2.5">문서형</th>
              </tr>
            </thead>
            <tbody class="tbody1 text-sm text-center">
              {saResult?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td class="p-2.5">{index + 1}</td>
                      <td class="p-2.5">
                        <a
                          href="#"
                          download
                          onClick={(e) => {
                            e.preventDefault();
                            toast.info(
                              `${result.document} 파일이 다운로드됩니다.`,
                            );
                          }}
                        >
                          {result.document}
                        </a>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }
  };

  const StatusCell = ({ step, title, desc }) => {
    const stepList = ['faStep', 'saStep', 'naStep'];
    return (
      <>
        <dl className="ml-4 mt-7 relative" id={step} style={{ pageBreakAfter: 'always' }}>
          <dt className="flex items-center">
            <span className="numbox1 bg_orange3 text-white mr-2">
              {_.indexOf(stepList, step) + 1}
            </span>
            <span className="flex flex-col">
              <span className="text-lg font-bold">{title}</span>

            </span>
          </dt>
          <dd className="mt-3 flex flex-col gap-2 border-2 p-4">
            {step == 'faStep' ? (
              <>
                - 입찰문서 내 빈도수 분석을 통해 주요 단어를 추출한 결과입니다.<br />
                - 빈도수 기반 주요 용어 : {faResult && faResult.slice(0, 5).map((v, i) => (i + 1) + ") " + v.word + "  ")}
              </>
            ) : step == 'saStep' ? (
              <>
                - 과거 프로젝트 입찰문서와의 비교를 통해 가장 유사했던 프로젝트의 목록을 제공합니다.<br />
                - 현 프로젝트와 가장 유사한 과거의 프로젝트는 <br />
                <div className="pl-4">
                  {saResult && saResult.slice(0, 5).map((v, i) => {
                    return <>
                      {i + 1}) {v.document} <br />
                    </>
                  })}
                </div>

              </>
            ) : step == 'naStep' ? (
              <>
                - 입찰문서 내 포함된 단어들간의 연결관계를 분석하여 주요 연관단어를 제공합니다.<br />
                - {naResult && naResult.length >= 1 ? `${_.first(naResult).term1}, ${_.first(naResult).term2}이(가) 가장 연관성이 높으며, ` : ``}
                가장 많은 용어"{faResult && faResult.length >= 1 ? `${_.first(faResult).word}` : ``}"와(과) 연관성이 높은 용어는 "{
                  faResult && faResult.length >= 1 ? (
                    _.chain(naResult)
                      .filter(item => item.term1 == (_.first(faResult).word) || item.term2 == (_.first(faResult).word))
                      .map(item => ({
                        term: item.term1 == (_.first(faResult).word) ? item.term2 : item.term1,
                        freq: parseInt(item.freq)
                      }))
                      .orderBy('freq', 'desc')
                      .head()
                      .get('term')
                      .value()
                  ) : (null)
                }" 입니다
              </>
            ) : null}

          </dd>
          <dd className="mt-3 flex gap-4 justify-center">
            {step != 'naStep' && <ResultTable step={step} />}

            {step == 'faStep' ? (
              <div className="w-96 border flex-center h-[350px] flex-col relative">
                {faResult && <WordCloudChart data={faResult} style={{}} useMask={false} />}
              </div>
            ) : step == 'saStep' ? (
              <div className="w-8/12 border flex-center flex-col relative">
                {saResult && <BarChart data={saResult} />}
              </div>
            ) : step == 'naStep' ? (
              <div className="w-96 border flex-center h-[350px] flex-col relative">
                {naResult && (
                  <NetWorkChart data={naResult} crowd={networkK} style={{}} useTopMenu={false} />
                )}
              </div>
            ) : null}
            {step == 'naStep' && <>
              <table className="w-4/12">
                <colgroup>
                  <col width="10%" />
                  <col width="*" />
                  <col width="*" />
                </colgroup>
                <thead className="thead2">
                  <tr className="f_15 text-center">
                    <th className="py-2 px-2.5 ">순위</th>
                    <th className="" colSpan={2}>강한 연결관계 목록</th>
                  </tr>
                </thead>
                <tbody className="tbody1 text-sm text-center">
                  {naResult?.slice(0, 5)?.map((result, index) => {
                    return (
                      <>
                        <tr>
                          <td className="p-2.5">{index + 1}</td>
                          <td className="p-2.5">{result.term1}</td>
                          <td className="p-2.5">{result.term2}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </>}

          </dd>
        </dl>
      </>
    );
  };
  return (
    <>
      <StatusCell
        key="faStep"
        step="faStep"
        title="빈도수 분석 결과"
      />
      <StatusCell
        key="saStep"
        step="saStep"
        title="유사도 분석 결과"
        desc="유사도 분석 결과 부연 설명"
      />
      <StatusCell
        key="naStep"
        step="naStep"
        title="네트워크 분석 결과"
        desc="네트워크 분석 결과 부연 설명"
      />
    </>
  )
}
