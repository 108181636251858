import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import AnalysisTargetList from './LLMPopComponents/AnalysisTargetList';
import LLMResultList from './LLMPopComponents/LLMResultList';
import axiosClient from '../../../../lib/axiosClient';
import { useSearchParams } from 'react-router-dom';
import ReactTable_CS from '../../../Components/ReactTable_CS';
import useTableControl from '../../../../hooks/useTableControl';
import useLoading from '../../../../hooks/useLoading';
import useExcel from '../../../../hooks/useExcelProcessor';
export default function LLMAnalysisPop({
  setIsOpen,
  APIWritingComplete,
  setAPIWritingComplete,
  analysisOption,
  setAnalysisOption,
  reAnalysis
}) {
  const [resultCheck, setResultCheck] = useState(analysisOption.useLlm || 0);
  const [emailRegist, setEmailRegist] = useState(false);
  // 이메일 등록 요청 현재는 단순히 버튼 클릭시 true로 변경
  const [requestEmailRegist, setRequestEmailRegist] = useState(false);
  const [resultList, setResultList] = useState();
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [searchParams, setSearchParams] = useSearchParams()
  const docId = analysisOption.docId
  const projectId = parseInt(searchParams.get("id"))
  const historyId = parseInt(searchParams.get("history"))
  const fileId = analysisOption.fileId
  const ansId = analysisOption.ansId

  useEffect(() => {
    setTotalCount(resultList?.length)
  }, [resultList])
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onSortingChange,
    searchOptions,
    filterOptions,
    setFilterOptionValue
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(resultList?.length);
  // 선택된 아이템을 저장하는 상태
  const [checkAll, setCheckAll] = useState(false);
  const handleSelectAll = () => {
    setCheckAll(prev => {
      const newCheckAll = !prev;
      setSelectedItems(() => {
        if (newCheckAll) {
          // 전체 선택
          return new Set(resultList.map(item => item.seq));
        } else {
          // 전체 선택 해제
          return new Set();
        }
      });
      return newCheckAll;
    });
  };


  // 개별 체크박스 핸들러
  const handleSelectItem = (itemId) => {
    setSelectedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        if (checkAll) {
          setCheckAll(false)
        }
        newSet.delete(itemId);
      } else {
        newSet.add(itemId);
      }
      return newSet;
    });
  };


  useEffect(() => {
    setColumns([
      {
        header: () => {
          return (
            <>
              <label className="check_box1 flex_center" onClick={handleSelectAll}>
                <input
                  type="checkbox"
                  className="r4"
                  name="1"
                  checked={checkAll}
                  onChange={() => { }}
                />
              </label>
            </>
          )
        },
        id: 'check',
        size: '7%',
        cellRender: (row) => {
          return (
            <>
              <label className="check_box1 flex_center" onClick={() => { handleSelectItem(row.seq) }}>
                <input
                  type="checkbox"
                  className="r4"
                  name="1"
                  checked={selectedItems.has(row.seq)}
                  onChange={() => { }}
                />
              </label>
            </>
          )
        }
      },
      {
        header: '번호',
        id: 'reverse',
        size: '13%',
      },
      {
        header: '문장',
        id: 'sentence',
        textAlign: 'text-left',
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <div className="text-red-600">{row.sentence}</div>;
          } else if (row.difficulty == 'MID') {
            return <div className="text-blue-600">{row.sentence}</div>;
          } else {
            return <div>{row.sentence}</div>;
          }
        }
      },
      {
        header: '난이도',
        id: 'difficulty',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'difficulty' })?.sort || '',
        },
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <span className="tdttbox px-2 bg-red-600/70">상</span>;
          } else if (row.difficulty == 'MID') {
            return <span className="tdttbox px-2 bg-lime-600/60">중</span>;
          } else {
            return <span className='tdttbox px-2 bg-sky-600/60'>하</span>;
          }
        },
        size: '15%',
        filter: () => {
          return (
            <>
              <select className='ml-2 px-2 py-1 border-2' onChange={(e) => { setFilterOptionValue('difficulty', e.target.value) }}>
                <option value={''}>
                  전체
                </option>
                {
                  [{ title: '상', value: 'HIGH' }, { title: '중', value: 'MID' }, { title: '하', value: 'LOW' }].map(v => {
                    return (
                      <option value={v.value}>
                        {v.title}
                      </option>
                    )
                  })
                }
              </select>
            </>
          )
        }
      }
    ]);
  }, [selectedItems, resultList, checkAll]);


  const { excelDownload, fileName } = useExcel({
    dataList: resultList,
    setDataList: setResultList,
    setTotalCount: setTotalCount,
    columnIdList: columns,
  });

  const saveLLMData = async () => {
    let data = { historyId, projectId, ansId, fileId, docId, useLlm: resultCheck, daResult: resultList }
    const response = await axiosClient.post("/api/analysis/saveLLMData", data)
    reAnalysis(3)
    setAnalysisOption({ ...analysisOption, useLlm: resultCheck })
    setIsOpen(false)
  }

  const getAiDifficultyList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAiDifficultyList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          historyId,
          projectId,
          llmData: 1
        },
        usePagination: false,
      },
    })
      .then(async (result) => {
        setResultList(result.data.data.rows);
        setTotalCount(result.data.data.rows?.length);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };


  useEffect(() => {
    // if (analysisOption.useLlm == 1) {
    getAiDifficultyList()
    // }
  }, [])
  const { LoadingElement, setLoading, loading } = useLoading({
    init: false,
    size: 14,
  });

  useEffect(() => {
    const list = _.cloneDeep(resultList) || []
    const difficultyToNumber = (difficulty) => {
      switch (difficulty) {
        case 'HIGH':
          return 3;
        case 'MID':
          return 2;
        case 'LOW':
          return 1;
        default:
          return 0; // 예외 처리용
      }
    };
    const sortData = (data) => {
      // 정렬 조건을 순회하면서 데이터를 정렬
      return [...data].sort((a, b) => {
        for (let option of sortOption) {
          const { sort, columnId } = option;

          // columnId가 "difficulty"인 경우 HIGH, MID, LOW를 각각 3, 2, 1로 변환
          const aValue = columnId === 'difficulty' ? difficultyToNumber(a[columnId]) : a[columnId];
          const bValue = columnId === 'difficulty' ? difficultyToNumber(b[columnId]) : b[columnId];
          if (aValue < bValue) return sort === 'asc' ? -1 : 1;
          if (aValue > bValue) return sort === 'asc' ? 1 : -1;
          // 같은 값일 때는 다음 정렬 조건을 확인
        }
        return 0;
      });
    };
    let result = sortData(list)
    setResultList(result)
  }, [sortOption])


  const getFilteredList = useCallback(() => {
    const list = _.cloneDeep(resultList) || []
    if (_.isEmpty(filterOptions)) {
      return list
    } else {
      let result = _.filter(list, v => {
        if (!_.isEmpty(filterOptions?.difficulty)) {
          if (v.difficulty == filterOptions?.difficulty && !_.isEmpty(filterOptions?.difficulty)) {
            return v
          }
        } else {
          return v
        }
      })
      return result
    }
  }, [filterOptions, resultList])

  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        {emailRegist != true ? (
          <>
            <ul className="pop_container pt-8" style={{ width: '850px' }}>
              <li className="shrink-0 px-8">
                <p className="poptit flex items-center">
                  <span className="img mr-2 mt-1">
                    <img src="/images/icon_select1.png" width="25" />
                  </span>
                  <span className="tit">LLM(ChatGPT) 기반 난이도 분석 </span>
                </p>
                <div className="grid grid-cols-3 gap-2">
                  <div className="col-span-2">
                    <p className="mt-5 text-black/60">
                      활용 GPT version :{' '}
                      <strong className="text-red-500 bold">
                        gpt-4o-mini-2024-07-18(검증정확도 : 0.874){' '}
                      </strong>
                    </p>
                    {/* <p className="mt-2 text-black/60">
                      *해당 기능 활용은 제한되어 있습니다. (현재남은 횟수{' '}
                      <strong className="text-red-500 bold">00</strong>회)
                    </p>
                    <p className="mt-2 pl-3 text-black/60">
                      그 이상 활용을 위해서는 우측 버튼을 통해 이메일주소를
                      공유해주시기 바랍니다.
                    </p> */}
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <div className="flex-1"></div>
                    {/* <a
                      href="javascript:void(0)"
                      className="btn_pop bg_orange3 text-white  text-center  r5 px-5"
                      onClick={() => {
                        setEmailRegist(true);
                      }}
                    >
                      이메일 등록 요청 및 API 키 작성
                    </a> */}
                  </div>
                </div>
                <a
                  href="javascript:void(0)"
                  className="close1 btn_pop2"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
                </a>
              </li>
              <li className="p-8 grow pop_contents ">
                <p className="mt-4 flex items-center gap-1 font-semibold text-lg">
                  분석대상 설정
                </p>
                <AnalysisTargetList docId={docId} setResultList={setResultList} setLoading={setLoading} />
                <LoadingElement />
                <ReactTable_CS
                  className="mt-4 w-full"
                  columns={columns}
                  data={getFilteredList()}
                  setData={setResultList}
                  totalCount={totalCount}
                  onSortingChange={onSortingChange}
                  pageInfo={pageInfo}
                />
                <a
                  href="javascript:void(0)"
                  className="btn_pop bg_orange3 text-white  text-center mt-2 mb-5 px-5 r5 col-span-1 float-right"
                  onClick={() => { excelDownload('LLM기반난이도분석결과', _.filter(resultList, v => selectedItems.has(v.seq))) }}
                >
                  분석결과 다운로드
                </a>
              </li>
              <li className="mt-2 pb-8  shrink-0 flex_center gap-1 text-center">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2">
                    <p className="mt-5 text-black/60">
                      이후 분석에 활용할 난이도 분석 결과 선택이 필요합니다.
                    </p>
                    <div
                      className={
                        resultList?.length > 0
                          ? 'grid grid-cols-2 gap-2'
                          : 'grid grid-cols-1 gap-2'
                      }
                    >
                      <p className="mt-2 text-black/60">
                        <input
                          type="radio"
                          name="result"
                          id="result_1"
                          checked={resultCheck == 0}
                          onChange={() => {
                            setResultCheck(0);
                          }}
                        />
                        <label htmlFor="result_1" className="pl-2">
                          자체 개발 언어모델 결과 활용
                        </label>
                      </p>
                      {
                        resultList?.length > 0 &&

                        <p className="mt-2 pl-3 text-black/60">
                          <input
                            type="radio"
                            name="result"
                            id="result_2"
                            checked={resultCheck == 1}
                            onChange={() => {
                              setResultCheck(1);
                            }}
                          />
                          <label htmlFor="result_2" className="pl-2">
                            LLM(ChatGPT)결과 활용
                          </label>
                        </p>
                      }
                    </div>
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="btn_pop bg_orange3 text-white  text-center mt-10 r5 col-span-1"
                    onClick={() => {
                      saveLLMData()
                    }}
                  >
                    완료
                  </a>
                </div>
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="pop_container pt-8" style={{ width: '850px' }}>
              <li className="shrink-0 px-8">
                <p className="poptit flex items-center">
                  <span className="img mr-2 mt-1">
                    <img src="/images/icon_select1.png" width="25" />
                  </span>
                  <span className="tit">LLM(ChatGPT) 기반 난이도 분석 </span>
                </p>
                <a
                  href="javascript:void(0)"
                  className="close1 btn_pop2"
                  onClick={() => {
                    setEmailRegist(false);
                  }}
                >
                  <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
                </a>
              </li>
              <li className="p-8 grow pop_contents">
                <div>
                  <p className="mt-5 text-black/60">1. 이메일 등록 요청</p>
                  <p className="mt-2 text-black/60">
                    * LLM 모델을 활용하기위한 Team 등록 목적임.
                  </p>
                  <p className="mt-2 pl-3 text-black/60">
                    관리자 승인을 위해 2-3일 소요. 문의전화 00-000-0000
                  </p>
                  <p className="mt-4 grid grid-cols-3 gap-2 items-center">
                    <input
                      type="text"
                      className="input_h45 f_15 px-3 r5 bg-slate-50 col-span-2"
                    />
                    <a
                      href="javascript:void(0)"
                      className="btn_h45 r5 px-5 bg_orange3 hover:bg-slate-700 col-span-1"
                      onClick={() => {
                        setRequestEmailRegist(true);
                      }}
                    >
                      <span className="ml-1 text-sm text-white font-bold ">
                        신청
                      </span>
                    </a>
                  </p>
                </div>
                {requestEmailRegist && (
                  <div className="mt-10">
                    <p className="mt-5 text-black/60">2. API 키 작성</p>
                    <p className="mt-2 text-black/60">
                      * 계정의 API 키를 작성해주세요. 해당 내용은 저장되지
                      않으며, 본 LLM 모델 구동을 위한 목적으로만 활용됩니다.
                    </p>
                    <p className="mt-4 grid grid-cols-3 gap-2 items-center">
                      <input
                        type="text"
                        className="input_h45 f_15 px-3 r5 bg-slate-50 col-span-2"
                      />
                      <a
                        href="javascript:void(0)"
                        className="btn_h45 r5 px-5 bg_orange3 hover:bg-slate-700 col-span-1"
                        onClick={() => {
                          setIsOpen(false);
                          setAPIWritingComplete(true);
                        }}
                      >
                        <span className="ml-1 text-sm text-white font-bold ">
                          완료
                        </span>
                      </a>
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </>
        )}

        <div className="popbg btn_pop2"></div>
      </article>
    </>
  );
}
