import { React, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactTable_CS from '../../../Components/ReactTable_CS';
import useTableControl from '../../../../hooks/useTableControl';
import axiosClient from '../../../../lib/axiosClient';
import { toast } from 'react-toastify';
export default function OntologyResultList({ setResultList, analysisOption, tab }) {
  const [difficultyList, setDifficultyList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoaded, setIsLoaded] = useState(false)
  const projectId = searchParams.get("id")
  const historyId = searchParams.get("history")
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    onPageChange,
    pageInfo,
    sortOption,
    onSortingChange,
    searchOptions,
    filterOptions,
    setFilterOptionValue
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);
  // 선택된 아이템을 저장하는 상태
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [checkAll, setCheckAll] = useState(false);

  const handleSelectAll = () => {
    setCheckAll(prev => {
      const newCheckAll = !prev;
      setSelectedItems(() => {
        if (newCheckAll) {
          // 전체 선택
          return new Set(difficultyList.map(item => item.difficultyId));
        } else {
          // 전체 선택 해제
          return new Set();
        }
      });
      return newCheckAll;
    });
  };

  // 개별 체크박스 핸들러
  const handleSelectItem = (itemId) => {


    setSelectedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        if (checkAll) {
          setCheckAll(false)
        }
        newSet.delete(itemId);
      } else {
        if (selectedItems.size <= 2) {
          newSet.add(itemId);
        } else {
          alert("온톨로지분석은 최대 3개까지 선택가능합니다.")
        }
      }
      return newSet;
    });
  };


  useEffect(() => {
    setColumns([
      {
        id: 'check',
        size: '7%',
        cellRender: (row) => {
          return (
            <>
              <label className="check_box1 flex_center" onClick={() => { handleSelectItem(row.difficultyId) }}>
                <input
                  type="checkbox"
                  className="r4"
                  name="1"
                  checked={selectedItems.has(row.difficultyId)}
                  onChange={() => { }}
                />
              </label>
            </>
          )
        }
      },
      {
        header: '번호',
        id: 'index',
        size: '13%',
      },
      {
        header: '문장',
        id: 'sentence',
        textAlign: 'text-left',
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <span className="text-red-600">{row.sentence}</span>;
          } else if (row.difficulty == 'MID') {
            return <span className="text-blue-600">{row.sentence}</span>;
          } else {
            return <span className="">{row.sentence}</span>;
          }
        }
      },
      {
        header: '난이도',
        id: 'difficulty',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'difficulty' })?.sort || '',
        },
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <span className="tdttbox px-2 bg-red-600/70">상</span>;
          } else if (row.difficulty == 'MID') {
            return <span className="tdttbox px-2 bg-lime-600/60">중</span>;
          } else {
            return <span className='tdttbox px-2 bg-sky-600/60'>하</span>;
          }
        },
        size: '15%',
        filter: () => {
          return (
            <>
              <select className='ml-2 px-2 py-1 border-2' onChange={(e) => {
                setFilterOptionValue('difficulty', e.target.value)
                onPageChange(1)
              }}>
                <option value={''}>
                  전체
                </option>
                {
                  [{ title: '상', value: 'HIGH' }, { title: '중', value: 'MID' }, { title: '하', value: 'LOW' }].map(v => {
                    return (
                      <option value={v.value}>
                        {v.title}
                      </option>
                    )
                  })
                }
              </select>
            </>
          )
        }
      }
    ]);

    setResultList?.([...selectedItems])
  }, [selectedItems, checkAll, difficultyList]);
  useEffect(() => {
    getAiDifficultyList();
  }, [pageInfo, sortOption, filterOptions]);

  useEffect(() => {
    setFilterOptionValue('difficulty', undefined)
  }, [tab])

  // 분석대상 문장 목록 조회
  // AI난이도 분석 결과 조회
  const getAiDifficultyList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAiDifficultyList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          ...filterOptions,
          historyId,
          projectId,
          llmData: analysisOption.useLlm,
          fromOntology: 1
        },
        usePagination: false
      },
    })
      .then(async (result) => {
        const list = result.data.data.rows
        setDifficultyList(list);
        setTotalCount(result.data.data.totalCount);
        if (!isLoaded) {
          setIsLoaded(true)
        }
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  useEffect(() => {
    if (isLoaded) {
      setSelectedItems(prev => {
        const newSet = new Set(prev);
        _.filter(difficultyList, v => v.difficulty == 'HIGH').slice(0, 3)?.forEach(item => {
          newSet.add(item.difficultyId)
        })

        return newSet;
      });
    }
  }, [isLoaded])

  if (tab == 1) {
    return (
      <>
        {difficultyList && (
          <>
            <ReactTable_CS
              className="mt-4 w-full"
              columns={columns}
              data={difficultyList}
              setData={setDifficultyList}
              totalCount={totalCount}
              onSortingChange={onSortingChange}
              pageInfo={pageInfo}
            />

          </>
        )}
      </>
    )

  } else return null
}