import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../../lib/axiosClient';
import xml2js from 'xml2js';
import dayjs from 'dayjs';
import useTableControl from '../../hooks/useTableControl';
import ReactTable_CS from '../Components/ReactTable_CS';
import useLoading from '../../hooks/useLoading';
export default function ProcurementPop({ setIsOpen, setValue, setUseProcurement, setProcureFileList }) {
  const [bimTitle, setBimTitle] = useState('');
  const [originBimList, setOriginBimList] = useState(undefined);
  const [bimList, setBimList] = useState(undefined);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedBim, setSelectedBim] = useState({})
  const [pageInfo, setPageInfo] = useState({ currentPage: 1, pageSize: 5 })
  const { LoadingElement, loading, setLoading } = useLoading({ init: true });
  const [columns, setColumns] = useState([
    {
      header: '번호',
      id: 'index',
      size: '7%',
    },
    {
      header: '사업명',
      id: 'bidNtceNm',
      textAlign: 'text-center',
    },
    {
      header: '발주처',
      id: 'ntceInsttNm',
      textAlign: 'text-center',
      size: '20%',
    },
    {
      header: '입찰방식',
      id: 'bidMethdNm',
      textAlign: 'text-center',
      size: '10%',
    },
    {
      header: '사업금액',
      id: 'asignBdgtAmt',
      textAlign: 'text-center',
      cellRender: (row) => {
        return <>{parseInt(row.asignBdgtAmt == '' ? 0 : row.asignBdgtAmt).toLocaleString()}원</>
      },
      size: '15%',
    }, {
      header: '첨부파일',
      id: 'bidClseDt',
      textAlign: 'text-center',
      cellRender: (row) => {
        const fileNameList = _.filter(Object.keys(row), key => _.includes(key, 'ntceSpecFileNm') && !_.isEmpty(row[key]))
          .map(key => {
            return row[key]
          })
        const fileCount = fileNameList?.length || 0
        return <>{fileCount}개</>
      },
      size: '10%',
    },
    {
      header: '제출기한',
      id: 'bidClseDt',
      textAlign: 'text-center',
      size: '15%',
    }
  ]);


  const getProcurementDataList = async () => {
    const endDate = dayjs().format("YYYYMMDDHHmm")
    const startDate = dayjs().subtract(6, 'month').format("YYYYMMDDHHmm");

    const data = {
      numOfRows: 200,
      pageNo: 1,
      ServiceKey: "lnZjzp7FI63JkmFg0++YIkZqj8WAbN22tKuQLZNWjxBAYno+zQrPVZ5DnqFwixRNoxQ3NZCVgfY8/j71ZGhDHw==",
      inqryDiv: 1,
      inqryBgnDt: startDate,
      inqryEndDt: endDate,
      bidNtceNm: 'bim'
    }
    const response = await axiosClient.get("https://apis.data.go.kr/1230000/BidPublicInfoService05/getBidPblancListInfoServcPPSSrch02",
      {
        params: data
      }
    )
    const xml = response.data;

    // xml2js를 이용하여 XML을 JSON으로 변환
    const json = await xml2js.parseStringPromise(xml, { explicitArray: false });
    setBimList(json.response.body.items.item);
    setOriginBimList(json.response.body.items.item)
    setTotalCount(json.response.body.totalCount)
    setLoading(false)
  }

  useEffect(() => {
    getProcurementDataList();
  }, [])

  const onPageChange = (page) => {
    setPageInfo({ currentPage: page, pageSize: 5 })
  }

  const searchBIM = () => {
    const filterdList = _.filter(originBimList, v => _.includes(v.bidNtceNm, bimTitle))
    setBimList(filterdList)
    setTotalCount(filterdList.length)
    onPageChange(1)
    setSelectedBim({})
  }

  const save = () => {
    let totalBudget;
    if (selectedBim.asignBdgtAmt == '' || selectedBim.asignBdgtAmt == null) {
      totalBudget = 0
    }
    console.log(" selected Row  : ", selectedBim)
    setValue('bizName', selectedBim.bidNtceNm)
    setValue('client', selectedBim.ntceInsttNm)
    setValue('bidType', selectedBim.bidMethdNm)
    setValue('totalBudget', Number(selectedBim.asignBdgtAmt).toLocaleString()); // 천 단위 콤마 적용
    setValue('deadline', selectedBim.bidClseDt)

    const fileURLList = _.filter(Object.keys(selectedBim), key => _.includes(key, 'ntceSpecDocUrl'))
      .map(key => {
        return selectedBim[key]
      })

    const fileNameList = _.filter(Object.keys(selectedBim), key => _.includes(key, 'ntceSpecFileNm'))
      .map(key => {
        return selectedBim[key]
      })
    const fileList = _.map(fileURLList, (v, i) => {
      let temp = {
        url: v,
        name: fileNameList[i]
      }
      return temp
    })
    console.log("🚀 ~ fileURLArray ~ fileURLArray:", fileList)
    setProcureFileList(_.filter(fileList, v => !_.isEmpty(v.name)))
    setIsOpen(false)
    setUseProcurement(true)
  }
  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '1200px' }}>
          <LoadingElement />
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/icon_setting1.png" width="22" />
              </span>
              <span className="tit">조달청 api 연계 </span>
            </p>
            <p className="mt-1 text-black/60">
              입찰공고를 선택하세요.
            </p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <div className="flex items-center gap-1">
              <label className='w-full'>
                <input
                  type="text"
                  className="input_h45 w-full f_15 px-3 r5 bg-slate-50"
                  placeholder="공고명을 입력해주세요."
                  value={bimTitle}
                  onChange={(e) => {
                    setBimTitle(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      searchBIM()
                    }
                  }}
                />
              </label>
              <a
                href="javascript:void(0)"
                className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
                onClick={searchBIM}
              >
                <img
                  src="/images/icon_search1.png"
                  alt="돋보기"
                  width="13"
                  className="shrink-0 imgwhite"
                />
                <span className="ml-1 text-sm text-white font-bold ">검색</span>
              </a>
            </div>
            {bimList && (
              <>
                <ReactTable_CS
                  className="mt-4 w-full"
                  columns={columns}
                  data={bimList}
                  setData={setBimList}
                  totalCount={totalCount}
                  resetPagination={false}
                  onPageChange={onPageChange}
                  pageInfo={pageInfo}
                  onRowClick={(row, index) => {
                    setBimList((prevData) =>
                      prevData.map((item, i) =>
                        row.bidNtceNo == item.bidNtceNo
                          ? {
                            ...item,
                            style: {
                              background: 'gainsboro',
                              color: 'white !important',
                            },
                          }
                          : {
                            ...item,
                            style: null,
                          },
                      ),
                    );
                    setSelectedBim(row);
                  }}
                />

              </>
            )}
          </li>
          <li className="mt-2 shrink-0 grid grid-cols-2 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white"
              onClick={save}
            >
              저장
            </a>
          </li>
        </ul>
        {/* <div className="popbg btn_pop1" onClick={() => { setIsOpen(false) }} style={{ background: 'transparent' }}></div> */}
      </article >
    </>
  );
}


