import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axiosClient from '../../../../lib/axiosClient';
import { useSearchParams } from 'react-router-dom';
import useExcel from '../../../../hooks/useExcelProcessor';
import useTableControl from '../../../../hooks/useTableControl';
import ReactTable from '../../../Components/ReactTable';
import ReactTable_CS from '../../../Components/ReactTable_CS';
import Pagination from '../../../Components/Pagination';
import dayjs from 'dayjs';
import _ from 'lodash';
import { toast } from 'react-toastify';
export default function AiDifficultyAnalysisOptionPop({
  setIsOpen,
  reAnalysis,
  setAnalysisOption
}) {
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = searchParams.get('history');
  const [usedModel, setUsedModel] = useState(0);
  const [checkedTrainingDatas, setCheckedTrainingDatas] = useState([]);
  const [model, setModel] = useState({});
  const [modelList, setModelList] = useState([]);
  const [userTrainingDataList, setUserTrainingDataList] = useState([]);
  const [file, setFile] = useState(null);
  const [changed, setChanged] = useState(false);
  const originModel = useRef(null)
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);
  const { excelDownload, excelUpload, fileName } = useExcel({
    dataList: userTrainingDataList,
    setDataList: setUserTrainingDataList,
    setTotalCount: setTotalCount,
    columnIdList: _.filter(columns, (v) => v.id != 'reverse'),
  });
  const getAiModelData = async () => {
    axiosClient({
      method: 'post',
      url: '/api/analysis/getAiModelData',
      data: { projectId, historyId },
    }).then((res) => {
      const trainingDataList = res.data.data.trainingDataList || [];
      const model = res.data.data.model || { modelId: 0, classifyMethod: 1 };
      const modelList = res.data.data.modelList || [];
      const totalCount = res.data.data.totalCount || 0;
      setModelList(modelList);
      setUserTrainingDataList(trainingDataList);
      setModel(model);
      originModel.current = model
      setTotalCount(totalCount);
    });
  };

  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
        size: '13%',
      },
      {
        header: '문장',
        id: 'sentence',
        textAlign: 'text-left',
      },
      {
        header: '난이도',
        id: 'difficulty',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'difficulty' })?.sort || '',
        },
        cellRender: (row) => {
          if (row.difficulty == '상') {
            return <span className="tdttbox px-2 bg-red-600/70">상</span>;
          } else if (row.difficulty == '중') {
            return <span className="tdttbox px-2 bg-lime-600/60">중</span>;
          } else {
            return <span className='tdttbox px-2 bg-sky-600/60'>하</span>;
          }
        },
        size: '18%',
      },
    ]);

    getAiModelData();
  }, []);

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, id, checkedList, setCheckedList) => {
    if (checked) {
      setCheckedList((prev) => [...prev, id]);
    } else {
      setCheckedList(checkedList.filter((el) => el !== id));
    }
  };

  // 체크박스 전체 선택
  const handleAllCheck = (checked, dataList, setCheckedList) => {
    if (checked) {
      const idArray = [];
      dataList.forEach((el) => idArray.push(el.num));
      setCheckedList(idArray);
    } else {
      setCheckedList([]);
    }
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setFile(file);
    setChanged(true)
    await excelUpload(e);
    e.target.value = '';
  };

  const saveAnalysisModel = async () => {
    if (projectStatus == 10) {
      return;
    }
    //엑셀 업로드한경우 신규모델생성, 아닌경우 선택된 모델정보만 분석인스턴스에 업데이트.
    //finetuning 인스턴스 데이터 적재중 상태로 생성.
    //finetuning 데이터 디비 저장.
    //적재끝나면, 해당 데이터및 파라미터로 파이썬 trainer.py실행. 모델 신규생성.
    //신규생성된 모델아이디로 분석인스턴스 모델아이디 셋
    //모델을 생성 또는 업데이트시, 업데이트끝나는거 웹소켓데이터로 확인해서 모델 상태가 학습완료일때 재분석 알럿띄워줌.
    /**
     * 
     * 
     * 모든 재학습동작시 분류방법에따라 사용자추가학습데이터만 붙여서 학습시키면되기때문에 코드상으로는 모두 기본 3분류, 2분류 모델을 가지고 재학습시킴. 
     * 신규모델이 생성되는 경우는 모델정보에서 기본모델을 선택하고 분류방법이 2이거나 사용자학습데이터가 추가되있을때만 신규모델.
     * 기존모델을 다시 재학습시키는 경우는 해당 모델명으로 파일만 갱신함.
     * 
     * 
     */
    const fileName = _.isEmpty(model.fileName) ? `${projectId}_${dayjs().format('YYMMDDHHmmss')}.h5` : model.fileName
    let createdModel;
    let modelChanged = originModel.modelId != model.modelId
    if (changed == false && modelChanged) {
      //모델만 변경하고 저장하는 경우는 분석인스턴스의 모델정보만 업데이트하고 재분석시켜야함.
      await axiosClient({
        method: 'post',
        url: '/api/analysis/updateInstanceAiModel',
        data: {
          projectId,
          historyId,
          classifyMethod: model.classifyMethod,
          modelId: model.modelId,
        },
      });
      setIsOpen(false);
      setAnalysisOption((prev) => {
        return { ...prev, modelId: model.modelId }
      })
      reAnalysis(2);
    } else {
      const response = await axiosClient({
        method: 'post',
        url: '/api/analysis/fineTuning',
        data: {
          projectId,
          historyId,
          classifyMethod: model.classifyMethod,
          trainingDataList: userTrainingDataList,
          fileName,
          modelId: model.modelId,
          modelChanged
        },
      });
      createdModel = response.data.data;
      setAnalysisOption((prev) => {
        return { ...prev, modelId: createdModel.modelId }
      })
      setIsOpen(false);

      axiosClient.post('/api/analysis/trainAiModel', createdModel);
    }

  };

  const changeClassifyMethod = async (value) => {
    setChanged(true)
    setModel({ ...model, classifyMethod: value });
  };

  const changeSelectedModel = async (e) => {
    setChanged(false)
    if (e.target.value != 0) {

      const selectedModel = _.find(modelList, {
        modelId: parseInt(e.target.value),
      });
      setModel((prev) => {
        return { ...prev, ...selectedModel };
      });

      const response = await axiosClient.get(
        '/api/analysis/getTrainingDataList',
        { params: { modelId: parseInt(e.target.value) } },
      );
      const dataList = response.data.data.list || [];
      const totalCount = response.data.data.totalCount || 0;
      setUserTrainingDataList(dataList);
      setTotalCount(totalCount);
    } else {
      setModel({ modelId: 0, classifyMethod: 1, fileName: '' })
      setUserTrainingDataList([]);
      setTotalCount(0);
    }
  };

  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '800px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/icon_setting1.png" width="22" />
              </span>
              <span className="tit">AI난이도 분석 세부옵션 설정 </span>
            </p>
            <p className="mt-1 text-black/60">
              AI난이도 분석 시 사용할 세부 옵션을 설정하세요.
            </p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <div id="addlist">
              <div id="tab2">
                <ul className="">
                  <li className="font-black text-lg">1. AI 분류 방법 선택</li>
                  <p className="text-black/70">
                    자체 개발된 AI 모델의 분류 방법을 선택합니다.
                  </p>
                </ul>
                <ul className="mt-2">
                  <li className="font-black">모델정보</li>
                  <li className="mt-1.5">
                    {model.modelId != -1 ? (
                      <>
                        <select
                          className="p-1 border border-black/20 r3 w-full"
                          value={model.modelId}
                          onChange={changeSelectedModel}
                        >
                          <option value="0" selected={model.modelId == 0}>
                            기본모델
                          </option>
                          {modelList?.map((v) => {
                            return (
                              <>
                                <option
                                  value={v.modelId}
                                  selected={model.modelId == v.modelId}
                                >
                                  {v.fileName}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </>
                    ) : (
                      <>신규모델</>
                    )}
                  </li>
                </ul>

                <ul className="mt-3">
                  <li className="font-black">분류 방법</li>
                  <p className="text-black/70">
                    난이도 분류 방법을 설정합니다.
                  </p>
                </ul>
                <ul className="mt-2 bg-stone-100/80 p-5 r5 ">
                  <li className="border-b pb-3.5">
                    <label className="flex items-center check_box2">
                      <input
                        type="radio"
                        name="1"
                        className="rounded-full"
                        checked={model.classifyMethod == 1}
                        onChange={(e) => {
                          changeClassifyMethod(1);
                        }}
                      />
                      <span className="ml-2 text-sm">
                        상, 중, 하 ( 기본값 )
                      </span>
                    </label>
                  </li>
                  <li className="mt-3.5 flex items-center">
                    <label className="flex items-center check_box2">
                      <input
                        type="radio"
                        name="1"
                        className="rounded-full"
                        onChange={(e) => {
                          changeClassifyMethod(2);
                        }}
                        checked={model.classifyMethod == 2}
                      />
                      <span className="ml-2 text-sm">
                        상, 하 ( 난이도 '중' 에 해당하는 데이터는 무시됩니다. )
                      </span>
                    </label>
                  </li>
                </ul>
              </div>
              <div className='tab2 mt-5'>
                <ul className="">
                  <li className="font-black text-lg">2. AI 모델 재학습</li>
                  <p className="text-black/70">
                    - 사용자가 기존의 모델을 파인튜닝(Fine-tuning)하여 활용 가능
                  </p>
                  <p className="text-black/70">
                    - AI모델 재학습 방법
                    <p className='pl-3'>
                      <p>
                        1) excel 다운로드
                      </p>
                      <p>
                        2) excel 양식에 맞게 사용자 학습데이터 추가
                      </p>
                      <p>
                        3) 작성한 excel 업로드
                      </p>
                      <p>
                        4) 저장
                      </p>
                      <p>
                        5) 재학습이 완료된 이후 재분석이 실행됩니다.
                      </p>
                    </p>
                  </p>
                </ul>
                <ul className="mt-3">
                  <li className="flex items-center justify-between">
                    <span className="font-black">불러오기</span>
                    <a
                      href="javascript:void(0)"
                      className="btn_h30 px-3 border border-black/20 r3 hover:bg-slate-100"
                      onClick={() => {
                        excelDownload(`AI모델_학습데이터.xlsx`);
                      }}
                    >
                      엑셀 다운로드
                      <img
                        src="/images/icon_down1.png"
                        alt=""
                        width="10"
                        className="inline-block pb-0.5"
                      />
                    </a>
                  </li>
                  <li className="mt-1.5 text-black/80">
                    <p className="mt-2 filebox  flex items-center w-full">
                      <input
                        className="upload-name mr-1 w-full"
                        value={file?.name || ''}
                        placeholder="문서를 선택해 주세요."
                        disabled
                      />
                      <input
                        type="file"
                        id="file"
                        className="w-full "
                        onChange={(e) => {
                          uploadFile(e);
                        }}
                      />
                      <label for="file" className="px-5 r5 shrink-0 ">
                        <span className="text-white">엑셀 업로드</span>
                      </label>
                    </p>
                  </li>
                </ul>
                <ul className="mt-3">
                  <li className="font-black">사용자 학습데이터</li>
                  <li className="mt-1.5 text-black/80">
                    <ReactTable_CS
                      className="mt-4 w-full"
                      columns={columns}
                      data={userTrainingDataList}
                      setData={setUserTrainingDataList}
                      totalCount={totalCount}
                      onSortingChange={onSortingChange}
                      pageInfo={pageInfo}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="mt-2 shrink-0 grid grid-cols-2 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white"
              onClick={() => {
                saveAnalysisModel();
              }}
            >
              {/* 모델만 변경할때는 저장버튼, 분류방법이 변경되거나 사용자학습데이터가 변경됐을때는 신규모델생성. */}
              저장
            </a>
          </li>
        </ul>

        <div className="popbg btn_pop1"></div>
      </article>
    </>
  );
}
