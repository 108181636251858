import { useEffect, useState } from "react";
import _ from "lodash";
import Neo4jGraph from '../../../Components/Neo4jGraph';
export default function OntologyResultTable({ ontologyResultList, selectedRow }) {
  const [currentOntology, setCurrentOntology] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [cypherQuery, setCypherQuery] = useState('');
  useEffect(() => {
    if (selectedRow != null) {
      console.log("🚀 ~ useEffect ~ selectedRow:", selectedRow)
      const difficultyId = selectedRow.difficultyId
      let current = _.find(ontologyResultList, { difficultyId }) || {}
      if (!_.isEmpty(current)) {
        const resultData = JSON.parse(current.resultData)
        current = {
          ...current,
          resultData: resultData
        }
        console.log("🚀 ~ useEffect ~ currentOntology:", ontologyResultList, current)
        setCurrentOntology(current)
        if (resultData.length > 0) {
          setSelectedData(current?.resultData?.[0])
          let data = current?.resultData?.[0]
          const keyword = data[0]['Task'] || ''
          const query = `MATCH (w {name : '${keyword}'}) MATCH (w)-[r]->(p) RETURN w, p,r`
          setCypherQuery(query)
          console.log("🚀 ~ useEffect ~ current?.resultData?.[0]:", data)
        } else {
          setCypherQuery('')
          setSelectedData([])
        }

      }
    }
  }, [selectedRow])

  const handleKeywordChange = (e) => {
    const value = e.target.value
    const resultData = currentOntology.resultData[value]
    setSelectedData(resultData);
    const keyword = resultData[0]['Task']
    const query = `MATCH (w {name : '${keyword}'})
                MATCH (w)-[r]->(p)
                RETURN w, p, r`
    setCypherQuery(query)
  }

  if (!_.isEmpty(currentOntology)) {
    return (
      <>
        <dl className="mt-8">
          <dt>
            <p className="text-lg font-bold">온톨로지 기반 문장 난이도 분석결과</p>
            <p className="mt-2 text-black/80">- 사전에 전문가 인터뷰 등을 통해 구축된 난이도 온톨로지를 활용하여 문장별 난이도에 대한 해석 제공
            </p>
            <p className="mt-2 text-black/80">- 온톨로지의 해석은 작업요구, 비용&인력, 협업/협의 분야별 난이도 분석 결과 및 그래프 제공
            </p>
            <p className="mt-2 text-black/80">- 해당 결과물은 주요 문장에 대한 상세분석자료로 활용가능
            </p>
          </dt>
          <dd className="mt-2  bg-slate-100/80 p-4 r5 text-sm text-black/60 leading-6">
            {/* 해당 문장은 복잡성이 높은(낮은) 기술로 특정한 도구가 요구되며(요구되지 않으며), 다수의 이해관계자가 참여하는(참여하지않는) BIM 기술입니다.<br />
            또한 반드시 요구되는 작업으로 판단됩니다.(작업이 아닙니다.) <br /> */}
            <dd className=" text-sm text-black/60 leading-6" dangerouslySetInnerHTML={{ __html: currentOntology.condition }}></dd>
            해당 문장의 주요 BIM 기술 키워드는 <span className="font-extrabold text-base">' {currentOntology.keywords} '</span>입니다.
            키워드 별 업무 프로세스 및 관련 작업, 요구 시술 수준은 아래 표와 같습니다.<br />
            따라서 해당 문장의 AI 분석결과는 <span className="font-extrabold text-indigo-700 text-base">난이도
              (상/중/하)</span>로
            평가되었습니다.
          </dd>
        </dl>
        <ul className="mt-8 flex items-stretch gap-10">
          <li className="grow">
            <div className="flex justify-between">
              <h4 className="flex items-center">
                <img src="/images/bullet_icon1.png" alt="icon" width="10" />
                <span className="ml-1.5 f_22 font-bold">키워드 별 업무 프로세스</span>
              </h4>
              {/* <a href="javascript:void(0)"
                  className="btn_pop1 btn_h35 bg-indigo-950/40 hover:bg-indigo-900 text-white r5 px-4">
                  요구 수준 기준
                </a> */}
            </div>
            <table className="mt-4 w-full ">
              <colgroup>
                <col width="15%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
                <col width="12%" />
                <col width="*" />
                <col width="10%" />
              </colgroup>
              <thead className="thead1">
                <tr className="text-center tracking-tighter">
                  <th className="p-2">주요 키워드 </th>
                  <th className="p-2">작업요구 기술수준</th>
                  <th className="p-2">비용&인력 투입 수준</th>
                  <th className="p-2">협업/협의 요구수준</th>
                  <th className="p-2">요구 작업순서</th>
                  <th className="p-2">상세설명</th>
                  <th className="p-2">관련 작업</th>
                </tr>
              </thead>
              <tbody className="tbody1 f_15 text-center tracking-tighter">
                {
                  selectedData.length > 0 ? (
                    <>
                      {
                        selectedData[1]['detail'].map((data, index) => {
                          const detail = data
                          const process = selectedData[1]['process'][index]
                          const relatedWork = selectedData[1]['related_work'][index]
                          const complexity = selectedData[0]['complexity']
                          const coordination = selectedData[0]['coordination']
                          const resource = selectedData[0]['resource']
                          return (
                            <>
                              <tr>
                                {
                                  index == 0 &&
                                  <>
                                    <td className="py-2.5 px-5" rowspan={selectedData[1]['detail'].length}>
                                      <select className="select1 w-full" onChange={handleKeywordChange}>
                                        {
                                          currentOntology.resultData.map((data, index) => {
                                            return (
                                              <>
                                                <option value={index}>{data[0]["Task"]}</option>

                                              </>
                                            )
                                          })
                                        }
                                      </select>
                                    </td>
                                    <td rowspan={selectedData[1]['detail'].length}>
                                      <span className="tdttbox px-2 bg-red-600/70">{complexity}</span>
                                    </td>
                                    <td rowspan={selectedData[1]['detail'].length}>
                                      <span className="tdttbox px-2 bg-lime-600/60">{resource}</span>
                                    </td>
                                    <td rowspan={selectedData[1]['detail'].length}>
                                      <span className="tdttbox px-2 bg-sky-600/70 ">{coordination}</span>
                                    </td>
                                  </>
                                }
                                <td className="py-2.5 px-5">
                                  <span className="text-indigo-950/70 font-extrabold">{process}</span>
                                </td>
                                <td className="py-2.5 px-5 text-left">
                                  {detail}
                                </td>
                                <td>{relatedWork}</td>
                              </tr>
                            </>
                          )
                        })
                      }
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={7}>
                          선택가능한 키워드가 존재하지않습니다.
                        </td>
                      </tr>
                    </>
                  )
                }
              </tbody>
            </table>
          </li>
          <li className="shrink-0 flex flex-col" style={{ width: '500px' }}>
            <h4 className="flex items-center">
              <img src="/images/bullet_icon1.png" alt="icon" width="10" />
              <span className="ml-1.5 f_22 font-bold">키워드 별 업무 프로세스</span>
            </h4>
            <div className="mt-4 border grow flex_center p-5">
              {
                _.isEmpty(cypherQuery) ? (
                  <>
                    선택가능한 키워드가 존재하지않습니다.
                  </>
                ) : (
                  <>

                    <Neo4jGraph query={cypherQuery} isTask={true} />
                  </>
                )
              }
            </div>
            <p className="mt-2 icon1 pl-3">선택되는 task에 따라 표출되는 온톨로지 그래프</p>
          </li>

        </ul>
        <div className='mt-5'>
          <dl>
            <dt class="icon1 pl-3.5 font-bold">작업의 기술 요구 수준</dt>
            <dd class="mt-1 border p-4 flex flex-col gap-1 r5">
              <p class="flex">
                <span class="tdttbox px-2 bg-red-600/60">상</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  BIM 설계에 투입되는 전체 자원(Resource)의 10% 이상 20% 미만
                </span>
              </p>
              <p class="flex">
                <span class="tdttbox px-2 bg-lime-600/60">중</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  BIM 설계에 투입되는 전체 자원(Resource)의 20% 이상
                </span>
              </p>
              <p class="flex">
                <span class="tdttbox px-2 bg-sky-600/60 ">하</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  BIM 설계에 투입되는 전체 자원(Resource)의 10% 미만
                </span>
              </p>
            </dd>
          </dl>
          <dl class="mt-6">
            <dt class="icon1 pl-3.5 font-bold">비용&인력 투입 수준</dt>
            <dd class="mt-1 border p-4 flex flex-col gap-1 r5">
              <p class="flex">
                <span class="tdttbox px-2 bg-red-600/60">상</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  복잡하고 정교한 작업으로, 고도의 전문 지식과 풍부한 경험이 필수적임
                </span>
              </p>
              <p class="flex">
                <span class="tdttbox px-2 bg-lime-600/60">중</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  중간 정도의 복잡성을 가지며, 일정 수준의 경험과 전문 지식이 요구됨
                </span>
              </p>
              <p class="flex">
                <span class="tdttbox px-2 bg-sky-600/60 ">하</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  단순하고 반복적인 작업으로, 특별한 기술이나 지식 없이도 충분히 수행 가능
                </span>
              </p>
            </dd>
          </dl>
          <dl class="mt-6">
            <dt class="icon1 pl-3.5 font-bold">협업 및 협의 요구 수준</dt>
            <dd class="mt-1 border p-4 flex flex-col gap-1 r5">
              <p class="flex">
                <span class="tdttbox px-2 bg-red-600/60">상</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  여러 팀, 부서 또는 외부 기관과 지속적인 협업이 필수적이며, 복잡한 의사결정과 조정이 필요함.
                </span>
              </p>
              <p class="flex">
                <span class="tdttbox px-2 bg-lime-600/60">중</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  작업을 완료하기 위해 다른 부서와 주기적인 협업과 조율이 필요함. 다른 팀이나 부서와의 일정 조율이 요구됨
                </span>
              </p>
              <p class="flex">
                <span class="tdttbox px-2 bg-sky-600/60 ">하</span>
                <span class="mt-0.5 ml-2 text-sm text-slate-700/90">
                  대부분 독립적으로 수행할 수 있으며, 다른 팀이나 부서와의 협업이 필요하지 않음. 단일 팀 내에서 이루어지는 작업
                </span>
              </p>
            </dd>
          </dl>
        </div>
      </>
    )

  } else return null
} 