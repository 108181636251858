import { React, useEffect, useRef, useState } from 'react';
import AiDifficultyAnalysisOptionPop from './AiDifficultyAnalysisOptionPop';
import useLoading from '../../../../hooks/useLoading';
import useTableControl from '../../../../hooks/useTableControl';
import LLMAnalysisPop from './LLMAnalysisPop';
import ReactTable from '../../../Components/ReactTable';
import Pagination from '../../../Components/Pagination';
import { useSearchParams } from 'react-router-dom';
import axiosClient from '../../../../lib/axiosClient';
import CompareModelPop from './CompareModelPop';

export default function AiDifficultyAnalysis({
  analysisResult,
  analysisInfo,
  reAnalysis,
  analysisOption,
  setAnalysisOption,
  downloadReport
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [LLMOpen, setLLMOpen] = useState(false);
  const [compareModelOpen, setCompareModelOpen] = useState(false);
  const status = analysisResult?.['daStep'] || analysisInfo?.['daStep'];
  const [APIWritingComplete, setAPIWritingComplete] = useState(false); // LLM 난이도 분석 API 인증
  const [difficultyCount, setDifficultyCount] = useState({ high: 0, mid: 0, low: 0 })
  const { LoadingElement, loading, setLoading } = useLoading({
    init: status == 1 ? true : false,
    size: 14,
  });
  const [aiDifficultyList, setAiDifficultyList] = useState([]);
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    filterOptions,
    setSearchOptionValue,
    getSearchOptionValue,
    getFilterOptionValue,
    setFilterOptionValue
  } = useTableControl({ setColumns, pageSize: 20 });
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = searchParams.get('history');

  useEffect(() => {
    setLoading(status == 1 ? true : false);
  }, [analysisResult]);

  useEffect(() => {
    getAiDifficultyList();
  }, [pageInfo, sortOption, analysisResult, filterOptions]);



  const debouncedSave = useRef(
    _.debounce(async (key, value) => {
      getAiDifficultyList();
    }, 500)
  ).current;

  // 컬럼명 설정
  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'index',
        size: '10%',
      },
      {
        header: '문장',
        id: 'sentence',
        textAlign: 'text-left',
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <span className="text-red-600">{row.sentence}</span>;
          } else if (row.difficulty == 'MID') {
            return <span className="text-blue-600">{row.sentence}</span>;
          } else {
            return <span className="">{row.sentence}</span>;
          }
        }
      },
      {
        header: '난이도',
        id: 'difficulty_order',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'difficulty_order' })?.sort || '',
        },
        cellRender: (row) => {
          if (row.difficulty == 'HIGH') {
            return <span className="tdttbox px-2 bg-red-600/70">상</span>;
          } else if (row.difficulty == 'MID') {
            return <span className="tdttbox px-2 bg-lime-600/60">중</span>;
          } else {
            return <span className='tdttbox px-2 bg-sky-600/60'>하</span>;
          }
        },
        size: '15%',
        filter: () => {
          return (
            <>
              <select className='ml-2 px-2 py-1 border-2' onChange={(e) => { setFilterOptionValue('difficulty', e.target.value) }}>
                <option value={''}>
                  전체
                </option>
                {
                  [{ title: '상', value: 'HIGH' }, { title: '중', value: 'MID' }, { title: '하', value: 'LOW' }].map(v => {
                    return (
                      <option value={v.value}>
                        {v.title}
                      </option>
                    )
                  })
                }
              </select>
            </>
          )
        }
      },
    ]);
  }, []);

  // AI난이도 분석 결과 조회
  const getAiDifficultyList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAiDifficultyList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          ...filterOptions,
          historyId,
          projectId,
        },
      },
    })
      .then(async (result) => {
        const list = result.data.data.rows
        const difficultyCount = result.data.data.count
        setAiDifficultyList(list);
        setTotalCount(result.data.data.totalCount);
        setDifficultyCount({ high: _.find(difficultyCount, { difficulty: 'HIGH' })?.count || 0, mid: _.find(difficultyCount, { difficulty: 'MID' })?.count || 0, low: _.find(difficultyCount, { difficulty: 'LOW' })?.count || 0 })
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  const search = () => {
    onPageChange(1)
    getAiDifficultyList()
  }


  return (
    <>
      <div className="ml-12 conbox relative" style={{ minHeight: '450px', overflowY: `${loading ? 'hidden' : 'auto'}` }}>
        <div className="flex items-center justify-between">
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">
              AI기반 난이도 분석 결과
            </span>
          </h4>
          <p className="flex gap-1">
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-600 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setCompareModelOpen(true)
              }}
            >
              AI 모델 성능비교
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-600 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setLLMOpen(true);
              }}
            >
              LLM 기반 분석
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => { reAnalysis(2) }}
            >
              재분석
            </a>
            <a
              onClick={downloadReport}
              className="btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900"
            >
              다운로드
            </a>
            <a
              href="javascript:void(0)"
              className="btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              전문가 모드
            </a>
          </p>
        </div>
        <LoadingElement />
        {/* {status == -1 && <div class="loading"></div>} */}
        <div className='mt-3 flex flex-col gap-1 '>
          <label>
            - 자체 개발한 기술수준 분류 AI 모델을 활용하여 판단된 AI 모델 기반 문장별 기술수준 판정 결과입니다.
          </label>
          <label>
            - 수급인이 대상 문장의 수행에 필요한 BIM 기술수준(난이도) 판정 결과로, 입찰 판단 및 문서작성에 활용할 수 있습니다.
          </label>
          <label>
            - 해당 문서에서 난이도 <span className='tdttbox px-2 bg-red-600/70 mx-1'>상</span> 문장의 개수는 {difficultyCount.high}개, 난이도 <span className='tdttbox px-2 bg-lime-600/70 mx-1'>중</span>개수는 {difficultyCount.mid}개, 난이도 <span className='tdttbox px-2 bg-sky-600/70 mx-1'>하</span>개수는 {difficultyCount.low}개입니다.
          </label>
          <label>
            - 예상 소요시간 : 01분 ~ 06분
          </label>
        </div>
        <div className="mt-4 flex items-center gap-1">
          <label>
            <input
              type="text"
              className="input_h45 f_15 px-3 r5 bg-slate-50"
              placeholder="문장을 입력해주세요."
              style={{ width: '350px' }}
              value={getSearchOptionValue('sentence')}
              onChange={(e) => {
                setSearchOptionValue('sentence', e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  search()
                }
              }}
            />
          </label>
          <a
            href="javascript:void(0)"
            className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
            onClick={search}
          >
            <img
              src="/images/icon_search1.png"
              alt="돋보기"
              width="13"
              className="shrink-0 imgwhite"
            />
            <span className="ml-1 text-sm text-white font-bold ">검색</span>
          </a>
        </div>
        <ReactTable
          className="mt-4 w-full"
          columns={columns}
          data={aiDifficultyList}
          setData={setAiDifficultyList}
          totalCount={totalCount}
          onSortingChange={onSortingChange}
          pageInfo={pageInfo}
        />
        <Pagination
          currentPage={pageInfo.currentPage}
          totalCount={totalCount}
          pageSize={pageInfo.pageSize}
          onPageChange={onPageChange}
        />

        {isOpen && <AiDifficultyAnalysisOptionPop setIsOpen={setIsOpen} reAnalysis={reAnalysis} setAnalysisOption={setAnalysisOption} />}
        {LLMOpen && (
          <LLMAnalysisPop
            setIsOpen={setLLMOpen}
            APIWritingComplete={APIWritingComplete}
            setAPIWritingComplete={setAPIWritingComplete}
            analysisOption={analysisOption}
            setAnalysisOption={setAnalysisOption}
            reAnalysis={reAnalysis}
          />
        )}
        {
          compareModelOpen && (
            <CompareModelPop setIsOpen={setCompareModelOpen} />
          )
        }
      </div>
    </>
  );
}
