import React, { useEffect, useRef } from 'react';
import NeoVis from 'neovis.js/dist/neovis.js';

const Neo4jGraph = ({ query, isTask = false, id = "viz", useControl = true, height = '800px', fromPDF = false }) => {
  const containerRef = useRef(null); // 그래프를 렌더링할 DOM 요소 참조
  const vizRef = useRef(null); // Neovis 인스턴스를 저장할 ref
  const uri = process.env.REACT_APP_NEO4J_URI;
  const user = process.env.REACT_APP_NEO4J_USER;
  const password = process.env.REACT_APP_NEO4J_PASSWORD;

  const taskUri = fromPDF ? process.env.REACT_APP_NEO4J_TASK_PDF_URI : process.env.REACT_APP_NEO4J_TASK_URI;
  const taskUser = process.env.REACT_APP_NEO4J_TASK_USER;
  const taskPassword = process.env.REACT_APP_NEO4J_TASK_PASSWORD;

  useEffect(() => {
    if (query != '') {
      console.log("🚀 ~ useEffect ~ query:", query)
      renderGraph();
    }
  }, [query])

  const renderGraph = () => {
    const config = {
      containerId: containerRef.current.id,  // 그래프를 렌더링할 요소의 ID
      neo4j: {
        serverUrl: isTask ? taskUri : uri,   // Neo4j 서버 URL
        serverUser: isTask ? taskUser : user,                  // Neo4j 사용자 이름
        serverPassword: isTask ? taskPassword : password,           // Neo4j 비밀번호
      },
      visConfig: {
        autoResize: true,
        height: '100%',
        width: '100%',
        locale: 'ko',
        clickToUse: false,
        nodes: {
          borderWidth: 4,
          borderWidthSelected: 4,
          brokenImage: undefined,
          chosen: true,
          color: {
            border: '#2B7CE9',
            background: '#97C2FC',
            highlight: {
              border: '#2B7CE9',
              background: '#D2E5FF'
            },
            hover: {
              border: '#2B7CE9',
              background: '#D2E5FF'
            }
          },
          opacity: 1,
          fixed: {
            x: false,
            y: false
          },
          font: {
            color: '#343434',
            size: 14, // px
            face: 'Nanum Gothic',
            background: 'none',
            strokeWidth: 0, // px
            strokeColor: '#ffffff',
            align: 'center',
            multi: false,
            vadjust: 0,
            bold: {
              color: '#343434',
              size: 14, // px
              face: 'arial',
              vadjust: 0,
              mod: 'bold'
            },
            ital: {
              color: '#343434',
              size: 14, // px
              face: 'arial',
              vadjust: 0,
              mod: 'italic',
            },
            boldital: {
              color: '#343434',
              size: 14, // px
              face: 'arial',
              vadjust: 0,
              mod: 'bold italic'
            },
            mono: {
              color: '#343434',
              size: 15, // px
              face: 'courier new',
              vadjust: 2,
              mod: ''
            }
          },
          group: undefined,
          heightConstraint: false,
          hidden: false,
          icon: {
            face: 'FontAwesome',
            code: '',
            weight: 400,
            size: 50,  //50,
            color: '#2B7CE9'
          },
          imagePadding: {
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
          },
          label: undefined,
          labelHighlightBold: true,
          level: undefined,
          mass: 1,
          physics: true,
          scaling: {
            min: 10,
            max: 30,
            label: {
              enabled: false,
              min: 14,
              max: 30,
              maxVisible: 30,
              drawThreshold: 5
            },
            customScalingFunction: function (min, max, total, value) {
              if (max === min) {
                return 0.5;
              }
              else {
                let scale = 1 / (max - min);
                return Math.max(0, (value - min) * scale);
              }
            }
          },
          shadow: {
            enabled: false,
            color: 'rgba(0,0,0,0.5)',
            size: 10,
            x: 5,
            y: 5
          },
          shape: 'circle',
          shapeProperties: {
            borderDashes: false, // only for borders
            borderRadius: 6,     // only for box shape
            interpolation: false,  // only for image and circularImage shapes
            useImageSize: false,  // only for image and circularImage shapes
            useBorderWithImage: false,  // only for image shape
            coordinateOrigin: 'center'  // only for image and circularImage shapes
          },
          size: 25,
          title: undefined,
          value: undefined,
          widthConstraint: 100,
          x: 0,
          y: 0
        },
        physics: {
          enabled: false,
          barnesHut: {
            theta: 0.5,
            gravitationalConstant: -2000,
            centralGravity: 0.3,
            springLength: 95,
            springConstant: 0.04,
            damping: 0.09,
            avoidOverlap: 0
          },
          forceAtlas2Based: {
            theta: 0.5,
            gravitationalConstant: -50,
            centralGravity: 0.01,
            springConstant: 0.08,
            springLength: 100,
            damping: 0.4,
            avoidOverlap: 0
          },
          repulsion: {
            centralGravity: 0.2,
            springLength: 200,
            springConstant: 0.05,
            nodeDistance: 100,
            damping: 0.09
          },
          hierarchicalRepulsion: {
            centralGravity: 0.0,
            springLength: 100,
            springConstant: 0.01,
            nodeDistance: 120,
            damping: 0.09,
            avoidOverlap: 0
          },
          maxVelocity: 50,
          minVelocity: 0.1,
          solver: 'barnesHut',
          stabilization: {
            enabled: true,
            iterations: 1000,
            updateInterval: 100,
            onlyDynamicEdges: false,
            fit: true
          },
          timestep: 0.5,
          adaptiveTimestep: true,
          wind: { x: 0, y: 0 }
        },
        edges: {
          color: { inherit: 'from' },
          arrows: {
            to: { enabled: true }
          },
          color: {
            color: '#848484',
            highlight: '#848484',
            hover: '#848484',
            inherit: 'from',
            opacity: 1.0
          },
          dashes: false,
          font: {
            color: '#343434',
            size: 14, // px
            face: 'arial',
            background: 'none',
            strokeWidth: 2, // px
            strokeColor: '#ffffff',
            align: 'horizontal',
            multi: false,
            vadjust: 0,
            bold: {
              color: '#343434',
              size: 14, // px
              face: 'arial',
              vadjust: 0,
              mod: 'bold'
            },
            ital: {
              color: '#343434',
              size: 14, // px
              face: 'arial',
              vadjust: 0,
              mod: 'italic',
            },
            boldital: {
              color: '#343434',
              size: 14, // px
              face: 'arial',
              vadjust: 0,
              mod: 'bold italic'
            },
            mono: {
              color: '#343434',
              size: 15, // px
              face: 'courier new',
              vadjust: 2,
              mod: ''
            }
          },
          hidden: false,
          hoverWidth: 1.5,
          labelHighlightBold: false,
          length: 100,
          physics: false,
          scaling: {
            min: 1,
            max: 5,
            label: {
              enabled: true,
              min: 14,
              max: 30,
              maxVisible: 30,
              drawThreshold: 5
            },
            customScalingFunction: function (min, max, total, value) {
              if (max === min) {
                return 0.5;
              }
              else {
                var scale = 1 / (max - min);
                return Math.max(0, (value - min) * scale);
              }
            }
          },
          selectionWidth: 1,
          selfReferenceSize: 200,
          selfReference: {
            size: 200,
            angle: Math.PI / 4,
            renderBehindTheNode: true
          },
          shadow: {
            enabled: false,
            color: 'rgba(0,0,0,0.5)',
            size: 10,
            x: 5,
            y: 5
          },
          smooth: {
            enabled: false,
            type: "dynamic",
            roundness: 0.5
          },
          title: undefined,
          value: undefined,
          width: 1,
          widthConstraint: false
        },
        interaction: useControl ? {
          dragNodes: true,
          dragView: true,
          hideEdgesOnDrag: false,
          hideEdgesOnZoom: false,
          hideNodesOnDrag: false,
          hover: true,
          hoverConnectedEdges: true,
          keyboard: {
            enabled: true,
            speed: { x: 10, y: 10, zoom: 0.02 },
            bindToWindow: true,
            autoFocus: true,
          },
          multiselect: true,
          navigationButtons: true,
          selectable: true,
          selectConnectedEdges: true,
          tooltipDelay: 300,
          zoomSpeed: 1,
          zoomView: true
        } : {},
        layout: {
          randomSeed: undefined,
          improvedLayout: true,
          clusterThreshold: 150,
          hierarchical: {
            enabled: false,
            levelSeparation: 150,
            nodeSpacing: 200,
            treeSpacing: 200,
            blockShifting: false,
            edgeMinimization: false,
            parentCentralization: true,
            direction: 'UD',        // UD, DU, LR, RL
            sortMethod: 'directed',  // hubsize, directed
            // shakeTowards: 'leaves'  // roots, leaves
          }
        },
        manipulation: {
          enabled: false,
          initiallyActive: false,
          addNode: true,
          addEdge: true,
          editNode: function (nodeData, callback) {
            // 기본 편집 함수
            console.log("Editing node:", nodeData);
            callback(nodeData);  // 편집 후 콜백 호출
          },
          editEdge: true,
          deleteNode: true,
          deleteEdge: true,
          controlNodeStyle: {
            // all node options are valid.
          }
        },
      },
      labels: isTask ? {
        "Task": {
          label: "name",
          "size": 20,
          "color": "#FF6347",
          "font": { "size": 11 }
        },
        "Process": {
          label: "name",
          "size": 20,
          "color": "#4682B4",
          "font": { "size": 12 }
        },
      } : {
        "Role": {
          "label": "name",
          "size": 20,
          "color": "#FF6347",
          "font": { "size": 11 }
        },
        "Stage": {
          "label": "name",
          "size": 20,
          "color": "#4682B4",
          "font": { "size": 12 }
        },
        "Step": {
          "label": "name",
          "size": 20,
          "color": "#32CD32",
          "font": { "size": 12 }
        },
      },
      relationships: isTask ? {
        "belong_to1": {
          [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
            caption: true,
            static: {
              color: "blue",
              width: 2
            },
            function: (relationship) => {
              console.log("🚀 ~ renderGraph ~ relationship:", relationship)
              return {
                color: relationship.properties.importance === "high" ? "red" : "gray",
                width: relationship.properties.weight || 1
              };
            }
          }
        },
      } : {
        "Include": {
          [NeoVis.NEOVIS_ADVANCED_CONFIG]: {
            function: {
              label: (edge) => edge.type
            }
          }
        },
      },
      initialCypher: query,
    };
    if (vizRef.current) {
      vizRef.current.clearNetwork();
    }

    vizRef.current = new NeoVis(config);
    vizRef.current.render();
  }

  return <div id={id} ref={containerRef} style={{ width: '100%', height: height }} />;
};

export default Neo4jGraph;