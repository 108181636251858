import { React, useState, useEffect, useRef, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../lib/axiosClient';
import { setUser } from '../../store/modules/Main';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/locale';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import ProcurementPop from './ProcurementPop';
import useLoading from '../../hooks/useLoading';

export default function App() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const loginUserData = useSelector((state) => state.Main.user);
  const navigate = useNavigate();
  const [showPop, setShowPop] = useState(false);
  const [useProcurement, setUseProcurement] = useState(false)
  const [procureFileList, setProcureFileList] = useState([])
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly
      style={{ width: '450px' }}
      className="textarea1 p-3 r5 bg-slate-50"
    />
  ));
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();
  const { LoadingElement, loading, setLoading } = useLoading({ init: false });

  const onsubmit = async (data) => {
    setLoading(true)
    const deadline = dayjs(data.deadline).format("YYYY-MM-DD HH:mm:ss")
    const totalBudget = parseInt(data.totalBudget.replace(/,/g, '')); // 콤마 제거
    const params = { ...data, deadline, totalBudget: totalBudget };
    try {
      const response = await axiosClient.post(
        '/api/project/insertProject',
        params,
      );
      console.log(response);
      if (response.data.success) {
        dispatch(
          setUser({
            user: {
              ...loginUserData,
              includedProjectList: response.data.projectRoleList,
            },
          }),
        );
        const projectId = response.data.projectId
        //조달청 연동여부에따라 조달청 첨부파일 저장.
        if (useProcurement && procureFileList.length > 0) {
          const response = await axiosClient.post("/api/analysis/saveProcurementFile", { fileList: procureFileList, projectId })
          setLoading(false)
        }

        navigate('/projects');
      }
    } catch (error) {
      console.log('🚀 ~ onsubmit ~ error:', error);
    }
  };

  const getDateValue = (value) => {
    if (typeof value === 'string') {
      return new Date(value);
    }
    return value instanceof Date ? value : new Date();
  };

  const showProcurementPop = () => {
    setShowPop(!showPop)
  }



  return (
    <>
      {
        ReactDOM.createPortal(<LoadingElement />, document.getElementById('main'))
      }
      <h3 className="flex items-center">
        <img src="/images/tit_icon1.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">프로젝트 생성</span>
      </h3>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onsubmit)}
        style={{ overflowY: 'auto' }}
      >
        <article className="mt-10  contentsbox px-5">

          <ul className="grid grid-cols-1 gap-8">
            <li>
              <p className="text-lg font-bold w-28">
                프로젝트명
                <span className="font-black text-red-600">*</span>
              </p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50 "
                    placeholder="프로젝트명을 입력하세요."
                    style={{ width: '450px' }}
                    {...register('projectName', {
                      required: '프로젝트명은 필수입력 항목입니다.',
                    })}
                  />
                  {errors.projectName && <p>{errors.projectName.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">사업명<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="사업명을 입력하세요."
                    style={{ width: '450px' }}
                    {...register('bizName', {
                      required: '사업명은 필수입력 항목입니다.',
                    })}
                  />
                  {errors.bizName && <p>{errors.bizName.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">발주처<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="발주처를 입력하세요."
                    style={{ width: '450px' }}
                    {...register('client', {
                      setValueAs: (value) => value.replaceAll(' ', ''),
                      required: '발주처는 필수입력 항목입니다.',
                    })}
                  />
                  {errors.client && <p>{errors.client.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">입찰방식<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="입찰방식을 입력하세요."
                    style={{ width: '450px' }}
                    {...register('bidType', {
                      required: '입찰방식은 필수입력 항목입니다.',
                    })}
                  />
                  {errors.bidType && <p>{errors.bidType.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">사업금액<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5">
                <input
                  className="textarea1 p-3 r5 bg-slate-50"
                  placeholder="사업금액을 입력하세요."
                  style={{ width: '450px' }}
                  rows="8"
                  {...register('totalBudget', {
                    required: '사업금액은 필수입력 항목입니다.',
                    validate: (value) => {
                      const regex = /^\d+$/;
                      const rawValue = value.replace(/,/g, ''); // 콤마 제거
                      return !regex.test(rawValue) ? '숫자만 입력가능합니다.' : true
                    },
                    onChange: (event) => {
                      const rawValue = event.target.value.replace(/[^\d]/g, ''); // 숫자만 남기기
                      if (/^\d*$/.test(rawValue)) { // 숫자인 경우에만 처리
                        setValue('totalBudget', Number(rawValue).toLocaleString()); // 천 단위 콤마 적용
                      }
                    }
                  })}
                />
                {errors.totalBudget && <p>{errors.totalBudget.message}</p>}
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">제출기한<span className="font-black text-red-600">*</span></p>
              <p className="mt-1.5">
                <Controller
                  name="deadline"
                  control={control}
                  defaultValue={getDateValue(getValues('deadline'))}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      locale={ko}
                      selected={getDateValue(value)}
                      onChange={(date) => {
                        const dateString = date
                          ? format(date, 'yyyy-MM-dd HH:mm:ss')
                          : '';
                        onChange(dateString);
                      }}
                      showTimeSelect
                      dateFormat="yyyy/MM/dd HH:mm:ss"
                      customInput={<CustomInput />}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (

                        <div
                          style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <button
                            type="button"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          <span style={{ margin: '0 10px' }}>
                            {format(date, 'yyyy년 MM월', { locale: ko })}
                          </span>
                          <button
                            type="button"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                      )}
                    />
                  )}
                />

                {errors.deadline && <p>{errors.deadline.message}</p>}
              </p>
            </li>

            {
              procureFileList.length > 0 &&

              <li>
                <p className="text-lg font-bold w-28">첨부파일</p>
                <p className="mt-1.5">
                  <dt>
                    {
                      procureFileList.map(v => {
                        return <> <dd className='p-2 '>
                          {v.name}
                        </dd></>
                      })
                    }
                  </dt>
                </p>
              </li>
            }
            <li>
              <p className="text-lg font-bold w-28">프로젝트 설명</p>
              <p className="mt-1.5">
                <textarea
                  className="textarea1 p-3 r5 bg-slate-50"
                  placeholder="발주처를 입력하세요."
                  style={{ width: '450px' }}
                  rows="8"
                  {...register('projectDesc', {
                  })}
                ></textarea>
                {errors.projectDesc && <p>{errors.projectDesc.message}</p>}
              </p>
            </li>
          </ul>
        </article>
      </form>
      <article className="mt-8 pt-8 shrink-0 border-t-2 flex justify-between">
        <p className="flex  gap-1.5">
          <a
            href="javascript:void(0)"
            className="btn_h45 btn_line r5 font-bold w-52"
            onClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            취소
          </a>
          <a href="javascript:void(0)" onClick={showProcurementPop} className="btn_h45 btn_line r5 font-bold w-52">
            조달청에서 불러오기
          </a>
        </p>
        <button
          onClick={(e) => {
            formRef.current.dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true }),
            );
          }}
          className="btn_h45 btn_blue r5 font-bold w-52"
        >
          저장
        </button>
      </article>

      {
        showPop &&
        <>
          <ProcurementPop setIsOpen={setShowPop} setValue={setValue} setUseProcurement={setUseProcurement} setProcureFileList={setProcureFileList} />
        </>
      }
    </>
  );
}
