import { useEffect, useState } from "react"
import AiAnalysisResultList from "./AiAnalysisResultList";
import OntologyResultList from "./OntologyResultList";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
export default function ReportOptionPop({ setIsOpen, analysisOption }) {
  const [tab, setTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const historyId = parseInt(searchParams.get('history'));
  const projectId = parseInt(searchParams.get('id'));
  const projectTitle = useSelector((state) => state.Main.currentProject.title);

  const [difficultyList, setDifficultyList] = useState([])
  const [ontologyList, setOntologyList] = useState([]);
  const downloadReport = async () => {
    toast.info('PDF가 생성중입니다. 잠시 후 다운로드가 시작됩니다.')
    fetch('http://kict.ai.phase.team:3030/downloadReport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url: "http://kict.ai.phase.team/reportPDF",
        reportData: { difficulty: difficultyList, ontology: ontologyList },
        historyId,
        projectId
      }),
    })
      .then((res) => {
        console.log(res);
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${projectTitle.replaceAll(" ", "_")}_분석결과.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setIsOpen(false)

  }
  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '800px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/icon_setting1.png" width="22" />
              </span>
              <span className="tit">AI난이도 분석결과 PDF 생성</span>
            </p>
            <p className="mt-1 text-black/60">
              PDF 생성시 사용할 문장을 설정하세요.
            </p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
            </a>
          </li>
          <li className="p-8 grow pop_contents">
            <ul id="tabs" className="gap-1">
              <li id={`${tab == 0 ? 'current' : ''}`}>
                <a
                  href="javascript:void(0)"
                  title="tab1"
                  className="px-4"
                  onClick={() => {
                    setTab(0);
                  }}
                >
                  AI 난이도 분석
                </a>
              </li>
              <li id={`${tab == 1 ? 'current' : ''}`}>
                <a
                  href="javascript:void(0)"
                  title="tab2"
                  className="px-4"
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  온톨로지 분석
                </a>
              </li>
            </ul>
            <div id="addlist" className="mt-5" style={{ minHeight: '303px' }}>
              <AiAnalysisResultList setResultList={setDifficultyList} tab={tab} />
              <OntologyResultList setResultList={setOntologyList} analysisOption={analysisOption} tab={tab} />
            </div>
          </li>
          <li className="mt-2 shrink-0 grid grid-cols-2 text-center">
            <a
              href="javascript:void(0)"
              className="btn_pop bg-neutral-300"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href="javascript:void(0)"
              className="btn_pop bg_orange3 text-white"
              onClick={downloadReport}
            >
              {/* 모델만 변경할때는 저장버튼, 분류방법이 변경되거나 사용자학습데이터가 변경됐을때는 신규모델생성. */}
              다운로드
            </a>
          </li>
        </ul>

        <div className="popbg btn_pop1"></div>
      </article>
    </>
  )
}